<template>
  <div class="home">
    <div class="header-nav-bar header-nav-bar--transparent">
      <!-- <div
        class="imgTitle"
        style="color: red;
        font-size: 23px;
        margin-right: 20px;
        font-weight: 700;
        font-family: 隶书;
    }"
      >
        CNSkonwall
      </div> -->
      <div class="nav-bar-block">
        <div class="bar-block-left">
          <a
            class="block-left-link"
            href="javascript:void(0)"
            style="height: 60px"
          >
            <img src="@/assets/img/img1.png" style="height: 100%" alt="" />
          </a>
          <!-- <div> -->
          <div
            class="block-left-module block-left-module--hidden"
            v-if="loginStatic"
          >
            <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('BottomContainAll')"
              style="color: #ff0000"
              >{{ $t("HomePage.navetop_left.dataAnalysis") }}</a
            >
            <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('PersonalCenter')"
              style="color: #c65f10"
              >{{ $t("HomePage.navetop_left.memberCenter") }}</a
            >
            <a
              class="left-module-link"
              href="https://ai.cnsknowall.com/"
              target="_Blank"
              style="color: #4874cb"
              >{{ $t("HomePage.navetop_left.LiteratureL") }}</a
            >
            <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('GPTS')"
              style="color: #c65f10"
              >{{ $t("HomePage.navetop_left.AITool") }}</a
            >
            <!-- <a
              class="left-module-link"
              href="https://gpt.cnsknowall.com/"
              target="_Blank"
              >GPT学术版</a
            > -->
            <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('OfficialAccountLists')"
              style="color: #d3b759"
              >{{ $t("HomePage.navetop_left.CNSReplication") }}</a
            >
            <!-- <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('ProteomeAnalysis')"
              >统计学模板</a
            > -->
            <!-- <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('ResearchChart')"
              style="color: #8ba6de"
              >{{ $t("HomePage.navetop_left.MechanismDiagram") }}</a
            > -->
            <a
              class="left-module-link"
              style="color: #8ba6de"
              href="https://aidraw.cnsknowall.com/"
              target="_Blank"
              >{{ $t("HomePage.navetop_left.MechanismDiagram") }}</a
            >
            <!-- <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('AboutOur')"
              >平台介绍</a
            > -->
            <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('Personalized')"
              style="color: #e91e63"
              >{{ $t("HomePage.navetop_left.personalized") }}</a
            >
            <!-- <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('')"
              style="color: #e91e63"
              >学术资源库</a
            > -->
            <!-- <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('MySelfAnalysis')"
              style="color: #e91e63"
              >个性化定制</a
            > -->
            <!-- <a
              class="left-module-link"
              href="https://www.bmbios.com/"
              target="_Blank"
              style="color: #dd587d"
              >组学服务</a
            > -->
            <!-- <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('OnlineService')"
              style="color: #8bc34a"
              >客服</a
            > -->
            <!-- <a
              class="left-module-link"
              href="javascript:void(0)"
              @click="jump2('jobWorker')"
              style="color: #03a9f4"
              >招聘</a
            > -->
            <!-- <a
              class="left-module-link"
              style="display: inline-block; position: relative"
              href="javascript:void(0)"
              @click="jump2('UseGiveMoney')"
              :class="{ 'shake-text': isShaking }"
              >引用奖励现金
              <span class="MoneyHot"
                ><img style="width: 30px" src="./img/hot.png" alt=""
              /></span>
            </a> -->
          </div>
          <!-- 在没有登陆的时候进行弹窗登录，并且没有登录的时候手动点击登录的时候也进行弹窗 -->
          <div
            class="block-left-module block-left-module--hidden"
            @click="openModal"
            v-else
          >
            <a
              class="left-module-link"
              style="color: #ff0000"
              href="javascript:void(0)"
              >{{ $t("HomePage.navetop_left.dataAnalysis") }}</a
            >
            <a
              class="left-module-link"
              style="color: #c65f10"
              href="javascript:void(0)"
              >{{ $t("HomePage.navetop_left.memberCenter") }}</a
            >
            <a
              class="left-module-link"
              style="color: #4874cb"
              href="javascript:void(0)"
              >{{ $t("HomePage.navetop_left.LiteratureL") }}</a
            >
            <a
              class="left-module-link"
              style="color: #c65f10"
              href="javascript:void(0)"
              >{{ $t("HomePage.navetop_left.AITool") }}</a
            >
            <a
              class="left-module-link"
              style="color: #d3b759"
              href="javascript:void(0)"
              >{{ $t("HomePage.navetop_left.CNSReplication") }}</a
            >
            <!-- <a class="left-module-link" href="javascript:void(0)">统计学模板</a> -->
            <a
              class="left-module-link"
              style="color: #8ba6de"
              href="javascript:void(0)"
              >{{ $t("HomePage.navetop_left.MechanismDiagram") }}</a
            >
            <a
              class="left-module-link"
              href="javascript:void(0)"
              style="color: #e91e63"
              >{{ $t("HomePage.navetop_left.personalized") }}</a
            >
            <!-- <a class="left-module-link" href="javascript:void(0)">平台介绍</a> -->
            <!-- <a
              class="left-module-link"
              style="color: #8bc34a"
              href="javascript:void(0)"
              >客服</a
            > -->
            <!-- <a
              class="left-module-link"
              style="color: #03a9f4"
              href="javascript:void(0)"
              >招聘</a
            > -->
            <!-- <a
              class="left-module-link"
              :class="{ 'shake-text': isShaking }"
              style="display: inline-block; position: relative"
              href="javascript:void(0)"
              >引用奖励现金 <span class="MoneyHot">hot</span></a
            > -->
            <!-- <a class="left-module-link" href="javascript:void(0)">高阶图表教学</a> -->
            <!-- <a
              class="left-module-link"
              href="javascript:void(0)"
              target="_Blank"
              >GPT学术版</a
            > -->
          </div>

          <!-- </div> -->
        </div>
        <div class="scrolling-container" style="border-radius: 10px">
          <div
            class="scrolling-text"
            :style="{ animationDuration: animationDuration }"
          >
            <p id="mooo">{{ $t("HomePage.navetop_right.subtitle") }}</p>
          </div>
        </div>
        <!-- <div class="scrolling-text-container">
          <p class="scrolling-text" style="margin-bottom: 0">
            跟着CNS每天学一张图表，培养科研数据思维，增强对科研数据的认知处理能力，进阶数据分析大师，提高文章发表速度！
          </p>
        </div> -->
        <!--  -->
        <div class="bar-block-right" v-if="loginStatic">
          <div
            class="login login_user"
            style="position: relative; height: 60px; line-height: 60px"
          >
            <img
              src="@/assets/img/default_userPhoto/default.jpg"
              alt=""
              v-if="!userPhotoUrl"
              style="
                height: 28px;
                margin-right: 8px;
                margin-bottom: 1px;
                vertical-align: middle;
                border-radius: 20%;
                position: relative;
              "
            />
            <img
              :src="userPhotoUrl"
              alt=""
              style="
                height: 28px;
                margin-right: 8px;
                margin-bottom: 1px;
                vertical-align: middle;
                border-radius: 20%;
              "
              v-else
            />
            <!--  -->
            <div
              class="user_name"
              style="display: flex; cursor: pointer"
              v-if="SVIPNum == 2"
            >
              <span
                style="color: #ebc346; font-family: fangsong; font-weight: 700"
                >{{ $t("HomePage.navetop_right.SVIP") }}</span
              >
              <span
                style="color: #ebc346; font-family: fangsong; font-weight: 700"
                >SVIP:</span
              >
              <span
                class="user_name"
                style="
                  display: inline-block;
                  padding: 0 5px;
                  max-width: 100px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  color: rgb(238, 79, 62);
                  font-family: cursive;
                  text-align: center;
                "
                >{{ logindata }}</span
              >
              <span
                style="color: #ebc346; font-family: fangsong; font-weight: 700"
                >{{ $t("HomePage.navetop_right.Hello") }}</span
              >
            </div>
            <!--  -->
            <div class="user_name" style="display: flex" v-else>
              <span
                style="
                  color: #909399;
                  font-family: fangsong;
                  font-weight: 672px;
                "
                v-if="!VipNum"
                >{{ $t("HomePage.navetop_right.user") }}</span
              >
              <span
                style="color: #ee4f3e; font-family: fangsong; font-weight: 700"
                v-else
                >{{ $t("HomePage.navetop_right.SVIP") }}</span
              >
              <span
                style="color: #ee4f3e; font-family: fangsong; font-weight: 700"
                v-if="VipNum"
                >VIP:</span
              >
              <span
                class="user_name"
                style="
                  display: inline-block;
                  padding: 0 5px;
                  max-width: 100px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  color: #e6a23c;
                  font-family: cursive;
                  text-align: center;
                "
                v-if="!VipNum"
                >{{ logindata }}</span
              >
              <span
                class="user_name"
                style="
                  display: inline-block;
                  padding: 0 5px;
                  max-width: 100px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  color: #909399;
                  font-family: cursive;
                  text-align: center;
                "
                v-else
                >{{ logindata }}</span
              >
              <span
                style="color: #909399; font-family: fangsong; font-weight: 700"
                v-if="!VipNum"
                >{{ $t("HomePage.navetop_right.Hello") }}</span
              >
              <span
                style="color: #ee4f3e; font-family: fangsong; font-weight: 700"
                v-else
                >{{ $t("HomePage.navetop_right.Hello") }}</span
              >
            </div>

            <div class="user_info">
              <!-- style="left: 1326px; top: 51px" -->
              <div
                class="ant-dropdown ant-dropdown-placement-bottomRight ant-dropdown-hidden"
                id="INFO_list"
              >
                <ul
                  class="ant-menu user-menu-component ant-menu-light ant-menu-root ant-menu-vertical login_user_infor"
                  role="menu"
                  style="
                    position: absolute;
                    top: 59px;
                    right: 0;
                    display: none;
                    width: 220px;
                    z-index: 999;
                  "
                >
                  <li
                    class="ant-menu-item menu-component-item"
                    role="menuitem"
                    style="border-top: none"
                  >
                    <div class="component-item-info">
                      <i class="iconfont1">
                        <svg
                          t="1730171927511"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="4241"
                          width="20"
                          height="20"
                        >
                          <path
                            d="M512 1022.1056c-282.3168 0-511.232-228.864-511.232-511.0784S229.632 0 512 0c282.3168 0 511.232 228.8128 511.232 511.0272 0 282.2656-228.864 511.0784-511.232 511.0784z m0-926.2592a415.2832 415.2832 0 0 0-415.3856 415.232c0 107.2128 41.0624 204.6464 107.8272 278.3232 60.16-29.1328 38.0416-4.9152 116.736-37.2736 80.5888-33.1264 99.6352-44.6464 99.6352-44.6464l0.768-76.288s-30.1568-22.8864-39.5264-94.72c-18.8928 5.4272-25.088-22.016-26.2144-39.424-1.024-16.896-10.9568-69.4784 12.0832-64.7168-4.7104-35.1744-8.0896-66.8672-6.4-83.6608 5.7344-58.88 62.976-120.5248 151.0912-124.9792 103.68 4.4544 144.7424 66.048 150.528 124.928 1.6384 16.8448-2.048 48.5376-6.7584 83.6096 23.04-4.6592 13.0048 47.872 11.8784 64.7168-1.024 17.408-7.3728 44.7488-26.2144 39.3728-9.4208 71.7824-39.5776 94.464-39.5776 94.464l0.7168 75.9296s19.0976 10.8032 99.6352 43.9296c78.6944 32.3584 56.576 9.5744 116.736 38.7584a413.184 413.184 0 0 0 107.776-278.3744A415.232 415.232 0 0 0 512 95.7952z"
                            fill="#4EB0FA"
                            p-id="4242"
                          ></path>
                        </svg>
                      </i>
                      <span
                        class="item-info-title"
                        style="margin-right: 10px"
                        >{{ logindata }}</span
                      ><span class="item-info-level" style="color: #53a9ff">
                        <!-- 如果是vip会员的话就显示会员标识 -->
                        <img
                          alt="用户等级"
                          src="@/assets/img/user_vip.png"
                          class="info-level-img"
                          v-if="VipNum" /></span
                      ><!-- Lv1 -->
                      <!-- Lv1后期需要后端传过来 -->
                    </div>
                    <!-- <div
                  class="component-item-property"
                  style="height: 50%; display: flex; align-items: center"
                >
                  <span class="item-property-title">云贝：</span
                  ><span class="item-property-count">5</span>
                </div> -->
                  </li>
                  <li
                    class="ant-menu-item component-item-module"
                    role="menuitem"
                    @click="Tojump('PersonalCenter')"
                  >
                    <i
                      class="iconfont icon-personal_center item-module-icon iconfont1"
                    >
                      <svg
                        t="1730173325973"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="9554"
                        width="20"
                        height="20"
                      >
                        <path
                          d="M625.3568 498.5344a30.7456 30.7456 0 0 1-20.0192-14.1312L511.744 330.3424 418.2016 484.4032a30.7712 30.7712 0 0 1-19.5584 14.0288c-8.1408 1.8432-16.6912 0.256-23.6544-4.352L305.664 448.2048l46.6944 128.1024h313.8048l43.6736-125.5936-60.5184 42.8544a30.80192 30.80192 0 0 1-23.9616 4.9664z"
                          fill="#FF623E"
                          p-id="9555"
                        ></path>
                        <path
                          d="M514.4576 51.6608c-253.3888 0-458.8544 205.4144-458.8544 458.8544 0 253.3888 205.4144 458.8544 458.8544 458.8544s458.8544-205.4144 458.8544-458.8544c-0.0512-253.44-205.4656-458.8544-458.8544-458.8544z m184.6784 708.608H320.7168c-16.9472 0-30.72-13.7728-30.72-30.72s13.7728-30.72 30.72-30.72h378.4192c16.9472 0 30.72 13.7728 30.72 30.72s-13.7728 30.72-30.72 30.72z m100.2496-380.0576l-82.3296 236.8512a30.73536 30.73536 0 0 1-29.0304 20.6336H330.9056a30.8224 30.8224 0 0 1-28.8768-20.1728l-86.016-235.9296a30.77632 30.77632 0 0 1 9.8304-34.6624 30.7712 30.7712 0 0 1 35.9936-1.4848l120.4736 79.7696 103.2192-170.0352a30.76096 30.76096 0 0 1 26.2656-14.7968c10.752 0 20.6848 5.5808 26.2656 14.7968l102.7072 169.1648L752.64 345.088a30.65856 30.65856 0 0 1 36.1984 0.512 30.6688 30.6688 0 0 1 10.5472 34.6112z"
                          fill="#FF623E"
                          p-id="9556"
                        ></path>
                      </svg>
                    </i>
                    <span class="item-module-left"
                      ><i
                        class="iconfont icon-personal_center item-module-icon"
                      ></i
                      >{{ $t("HomePage.navetop_left.memberCenter") }}</span
                    >
                  </li>
                  <li
                    class="ant-menu-item component-item-module"
                    role="menuitem"
                    @click="Tojump('News')"
                  >
                    <!-- @click="Tojump('News')" -->
                    <i
                      class="iconfont icon-message_linear1 item-module-icon iconfont1"
                    >
                      <svg
                        t="1730173487381"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="10583"
                        width="20"
                        height="20"
                      >
                        <path
                          d="M830.72 748.8L768 636.373333V490.666667c0-108.586667-68.693333-201.813333-164.693333-238.72v-0.213334c2.773333-8.746667 4.48-17.92 4.48-27.733333 0.213333-53.12-42.666667-96-95.786667-96s-96 42.88-96 96c0 9.813333 1.92 18.986667 4.48 27.733333v0.213334C322.773333 290.346667 256 388.906667 256 498.56v135.893333L193.066667 748.8c-10.026667 18.346667 3.2 40.533333 23.893333 40.533333h144.213333c21.973333 62.08 81.066667 106.666667 150.613334 106.666667s128.64-44.586667 150.613333-106.666667h144.213333c21.12 0 34.346667-22.4 24.106667-40.533333zM482.56 236.586667c-1.706667-3.84-2.56-8.106667-2.56-12.586667 0-17.706667 14.293333-32 32-32a31.957333 31.957333 0 0 1 29.44 44.373333c-9.6-1.066667-19.413333-1.706667-29.226667-1.706666-8.32 0-16.853333 0.426667-25.386666 1.28l-4.266667 0.64zM512 832c-32.426667 0-61.866667-16.64-79.36-42.666667h158.72c-17.493333 26.026667-46.933333 42.666667-79.36 42.666667z"
                          p-id="10584"
                        ></path>
                      </svg>
                    </i>
                    <span class="item-module-left"
                      ><i
                        class="iconfont icon-message_linear1 item-module-icon"
                      >
                      </i
                      >{{ $t("HomePage.navetop_right.message") }}</span
                    >
                  </li>
                  <!-- <li class="ant-menu-item component-item-module" role="menuitem">
                <span class="item-module-left"
                  ><i class="iconfont icon-g_management item-module-icon"></i
                  >视频中心</span
                >
              </li> -->
                  <li
                    class="ant-menu-item component-item-module"
                    role="menuitem"
                    @click="log_off"
                  >
                    <i class="iconfont icon-quit item-module-icon iconfont1">
                      <svg
                        t="1730173648966"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="15220"
                        width="20"
                        height="20"
                      >
                        <path
                          d="M90.202458 870.20046v-716.298591h511.641851v255.820925h-61.397022v-194.423903h-388.847807v593.504547h388.847807v-194.423904h61.397022v255.820926h-511.641851z m561.885081-167.818527l170.069751-163.009094-445.435395 3.274508v-61.499351l445.435395 3.274508-170.069751-162.906765 55.359648-26.503048 226.452683 216.936145-226.452683 216.936145-55.359648-26.503048z"
                          fill="#FF6100"
                          p-id="15221"
                        ></path>
                      </svg>
                    </i>
                    <span class="item-module-left"
                      ><i class="iconfont icon-quit item-module-icon"></i
                      >{{ $t("HomePage.navetop_right.layOut") }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- <div class="block-right-after">
           
            <a
              class="right-after-project right_item"
              href="javascript:void(0);"
              @click="jump('PersonalCenter')"
              >项目中心</a
            >
            <div class="img1">
              <img
                alt="头像"
                class="right-after-avatar ant-dropdown-trigger header-nav-bar-dropdown right_item"
                src="https://genescloud.oss-cn-shanghai.aliyuncs.com/web/images/avatar/default_avatar/default.png"
              />
              <ul class="img1_center">
                <li><div>1</div></li>
                <li><div>2</div></li>
                <li><div>3</div></li>
                <li><div>4</div></li>
              </ul>
            </div>
          </div> -->
        </div>
        <div
          class="login"
          style="color: #1d6af2; font-size: 18px; cursor: pointer"
          v-else
          @click="openModal"
        >
          {{ $t("HomePage.navetop_right.login") }}
        </div>
        <!-- <el-select v-model="value" placeholder="请选择" style="width:80px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            size="mini"
            style="padding-left:10px"
          >
          </el-option>
        </el-select> -->
        <!-- <div
          class="lang"
          v-if="$i18n.locale == 'zh'"
          style="font-weight: 700"
          @click="switchLanguage1"
        >
          语言: 中文
        </div>
        <div
          class="lang"
          v-else
          @click="switchLanguage1"
          style="font-weight: 700"
        >
          Language: English
        </div> -->
        <!-- <div class="lang">EN</div> -->
      </div>
      <!-- 登录模态框 -->
      <div v-if="modalOpen" class="modal">
        <div
          class="modal-content"
          ref="modalContent"
          :style="{
            top: modalY + 'px',
            left: modalX + 'px',
            position: 'absolute',
            padding: '36px',
            width: '847px',
          }"
          @mousedown="startDrag"
        >
          <span class="close" style="right: 3px; top: -5px" @click="closeModal"
            >&times;</span
          >
          <div
            class="login-all"
            style="
              display: flex;
              justify-content: center;
              height: 100%;
              align-items: center;
            "
          >
            <div class="container">
              <!-- 左边的图片 -->
              <div class="left">
                <div class="top">一图胜千言</div>
                <div class="img">
                  <img src="@/assets/img/bgi1.png" alt="" />
                  <!-- <img src="../assets/img/img1.png" alt="" /> -->
                </div>
                <div class="center">让天下没有难做的数据分析</div>
              </div>
              <div class="row justify-content-center" style="margin-bottom: 0">
                <!-- <div v-show="!showPopup" class="col-4">
                  <h4 class="passport-title">短信验证码登录</h4>
                  <form id="form_login" class="form-daniu passport-ctr">
                    <input
                      name="_token"
                      type="hidden"
                      value="VsByCDep8ouFul59zvDCcTJAS0jnPJswf5zfVQoY"
                    />

                    <input
                      name="_go"
                      type="hidden"
                      value="https://www.pmdaniu.com/"
                    />
                    <div class="form-group">
                      <input
                        placeholder="请输入手机号"
                        class="form-control"
                        name="email"
                        ref="inputphone"
                        type="text"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        placeholder="短信验证码"
                        class="form-control"
                        name="password"
                        value=""
                        ref="phoneCode"
                        @input="getPhoneCode"
                      />

                      <button
                        type="button"
                        class="btn btn-primary yanzheng"
                        :disabled="counting"
                        @click="startCountdown"
                      >
                        <vue-countdown
                          v-if="counting"
                          :time="60000"
                          @end="onCountdownEnd"
                          v-slot="{ totalSeconds }"
                        >
                          {{ totalSeconds }}S</vue-countdown
                        >
                        <span style="color: #fff" v-else>发送验证码</span>
                      </button>
                    </div>
   
                    <el-button
                      class="btn btn-success form-control"
                      @click="loginUp"
                    >
                      登录
                    </el-button>
                  </form>
                </div> -->
                <!-- <transition name="fade"> -->
                <div>
                  <div class="popup-content">
                    <iframe
                      :src="targetURL"
                      width="100%"
                      height="100%"
                      frameborder="0"
                      scrolling="no"
                    ></iframe>
                    <div
                      class="littleTip"
                      style="
                        position: absolute;
                        bottom: 26px;
                        right: 93px;
                        font-size: 19px;
                        color: red;
                      "
                    >
                      注册登录后即可免费使用!!
                    </div>
                    <div class="close-btn" @click="closePopup">
                      <div class="img" @click="togglePopup">
                        <!-- <div class="txt">切换成手机号登录</div> -->
                        <img src="@/assets/img/weixin.png" alt="" />
                      </div>
                      <div class="back"></div>
                    </div>
                  </div>
                </div>
                <!-- </transition> -->
              </div>
              <!-- <div
                v-show="!showPopup"
                class="img"
                id="login_wx"
                style="position: absolute; top: 0; right: 0; display: flex"
                @click="togglePopup"
              >
                <div class="txt">切换成微信登录</div>
                <img src="@/assets/img/weixin.png" alt="" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 进来就弹出来公告 -->
    <div v-if="modalOpen1" class="modal">
      <div
        class="modal-content content-height"
        ref="modalContent"
        :style="{
          top: modalY + 'px',
          left: modalX + 'px',
          position: 'absolute',
          padding: '30px',
          width: '800px',
        }"
        @mousedown="startDrag"
      >
        <!-- overflow: 'auto', -->
        <!-- maxHeight: '760px', -->
        <span class="close" style="right: 3px; top: -5px" @click="closeModal1"
          >&times;</span
        >
        <el-collapse
          v-model="activeNames"
          @change="handleChange"
          accordion
          style="
            padding-right: 10px;
            margin-right: -10px;
            overflow: auto;
            height: 94%;
          "
        >
          <el-collapse-item
            v-for="(item, index) in chartsList.data"
            :key="item.id"
            :title="item.title"
            :name="String(index + 1)"
          >
            <div
              class="login-all"
              style="
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;
              "
            >
              <div
                class="container1"
                v-html="convertedHTML"
                style="overflow: auto; text-align: center"
              ></div>
            </div>
            <!-- <div class="num"> {{chartsNumber[index]}} </div> -->
          </el-collapse-item>
        </el-collapse>
        <div
          class="Konw"
          style="
            color: red;
            width: 100%;
            text-align: center;
            line-height: 42px;
            position: absolute;
            right: 0px;
            bottom: 9px;
          "
        >
          <span
            style="
              color: #fff;
              display: flex;
              height: 100%;
              width: 93px;
              margin: 0px auto;
              justify-content: center;
              background: #ffa900;
              border-radius: 5px;
              cursor: pointer;
            "
            @click="closeModal1"
            >我已知晓</span
          >
        </div>
        <!-- <div
          class="login-all"
          style="
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
          "
        >
          <div
            class="container1"
            v-html="convertedHTML"
            style="max-height: 580px; overflow: auto; text-align: center;overflow-x: hidden;"
          ></div>
        </div> -->
      </div>
    </div>
    <!-- 背景轮播 -->
    <div class="turn_on">
      <el-carousel height="617px" :autoplay="false">
        <!--  -->
        <el-carousel-item style="padding: 30px">
          <div class="box">
            <div id="one">
              <ul class="ul-one">
                <li class="item" v-for="(item, index) in imgOne" :key="index">
                  <a
                    :href="'https://cnsknowall.com/#/' + item.name"
                    style="display: inline-block; height: 100%; width: 100%"
                    target="_blank"
                  >
                    <img :src="item.imgurl" alt=""
                  /></a>
                </li>
                <!-- 轮播主图 -->

                <!-- 轮播完成防止留白 -->
                <!-- 怎么确定尾部需要几张防止留白，3 张是怎么得出的？其实就是上面： 盒子宽度 / 单个图片宽度 = 一屏几张留白图 -->
              </ul>
            </div>
            <div id="two">
              <ul class="ul-one">
                <li class="item" v-for="(item, index) in imgTwo" :key="index">
                  <a
                    :href="'https://cnsknowall.com/#/' + item.name"
                    style="display: inline-block; height: 100%; width: 100%"
                    @click="ToChart(item.name)"
                    target="_blank"
                  >
                    <img :src="item.imgurl" alt=""
                  /></a>
                </li>
              </ul>
            </div>
            <div class="part2-plots-shadow-left"></div>
            <div class="part2-plots-shadow-right"></div>
          </div>
        </el-carousel-item>
        <!-- <el-carousel-item
          v-for="item in bgc_img"
          :key="item"
          :style="{
            backgroundImage: `url(${item})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
        >
        </el-carousel-item> -->
      </el-carousel>
      <!-- 背景中的bottom -->
      <div class="h-modules-box" v-if="loginStatic">
        <ul class="h-modules">
          <li class="h-modules-item2">
            <a href="javascript:void(0)" @click="jump2('BottomContainAll')"
              ><div>
                <img src="./img/data1.png" style="width: 63px" />
                <div>
                  <div class="title">
                    {{ $t("HomePage.nave_b.first.dataAnalysis") }}
                  </div>
                  <div class="txt">{{ $t("HomePage.nave_b.first.text") }}</div>
                </div>
              </div></a
            >
          </li>
          <li class="h-modules-item2">
            <a href="https://ai.cnsknowall.com/" target="_Blank"
              ><div>
                <img src="./img/write1.png" style="width: 63px" />
                <div>
                  <div class="title">
                    {{ $t("HomePage.nave_b.second.writingTtools") }}
                  </div>
                  <div class="txt">{{ $t("HomePage.nave_b.second.text") }}</div>
                </div>
              </div></a
            >
          </li>
          <li class="h-modules-item2">
            <a href="javascript:void(0)" @click="jump2('GPTS')">
              <div>
                <!-- @click="jump('BottomContainAll')" -->
                <img src="./img/gpt1.png" style="width: 63px" />
                <div>
                  <div class="title">
                    {{ $t("HomePage.nave_b.third.AITool") }}
                  </div>
                  <div class="txt">{{ $t("HomePage.nave_b.third.text") }}</div>
                </div>
              </div></a
            >
          </li>
          <!-- <li class="h-modules-item2">
            <a href="https://gpt.cnsknowall.com/" target="_Blank"
              ><div>
                <img
                  src="https://public.genescloud.cn/HomePage/homepage_icon/cloud_college.svg"
                  style="width: 63px"
                />
                <div>
                  <div class="title">科研版GPT</div>
                  <div class="txt">丰富又多样化的专业课程</div>
                </div>
              </div></a
            >
          </li> -->
        </ul>
      </div>
      <!--  -->
      <div class="h-modules-box" v-else>
        <ul class="h-modules" @click="openModal">
          <li class="h-modules-item2">
            <a href="javascript:void(0)"
              ><div>
                <img src="./img/data1.png" style="width: 63px" />
                <div>
                  <div class="title">
                    {{ $t("HomePage.nave_b.first.dataAnalysis") }}
                  </div>
                  <div class="txt">{{ $t("HomePage.nave_b.first.text") }}</div>
                </div>
              </div></a
            >
          </li>
          <li class="h-modules-item2">
            <a href="javascript:void(0)"
              ><div>
                <img src="./img/write1.png" style="width: 63px" />
                <div>
                  <div class="title">
                    {{ $t("HomePage.nave_b.second.writingTtools") }}
                  </div>
                  <div class="txt">{{ $t("HomePage.nave_b.second.text") }}</div>
                </div>
              </div></a
            >
          </li>
          <li class="h-modules-item2">
            <a href="javascript:void(0)">
              <div>
                <!-- @click="jump('BottomContainAll')" -->
                <img src="./img/gpt1.png" style="width: 63px" />
                <div>
                  <div class="title">
                    {{ $t("HomePage.nave_b.third.AITool") }}
                  </div>
                  <div class="txt">{{ $t("HomePage.nave_b.third.text") }}</div>
                </div>
              </div></a
            >
          </li>
          <!-- <li class="h-modules-item2">
            <a href="https://gpt.cnsknowall.com/" target="_Blank"
              ><div>
                <img
                  src="https://public.genescloud.cn/HomePage/homepage_icon/cloud_college.svg"
                  style="width: 63px"
                />
                <div>
                  <div class="title">科研版GPT</div>
                  <div class="txt">丰富又多样化的专业课程</div>
                </div>
              </div></a
            >
          </li> -->
        </ul>
      </div>
    </div>
    <div class="bottom_contain">
      <div class="">
        <div class="h-section-part2-title">
          {{ $t("HomePage.dataDetail.text") }}
        </div>
        <div class="h-section-part2-subtitle">
          <div
            class="text-slider"
            style="
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
          >
            <p style="transition: opacity 0.5s ease-in-out">
              {{ currentText }}
            </p>
          </div>
        </div>
        <div
          class="text"
          style="
            display: flex;
            background: #fff;
            padding: 25px;
            margin-bottom: 20px;
          "
        >
          <div
            data-v-923d6e98=""
            class="h-section-part2-analyse"
            style="margin-right: 20px"
          >
            <a
              data-v-923d6e98=""
              href="javascript:void(0)"
              class="part2-analyse-list-more"
              style="text-decoration: none"
              ><span data-v-923d6e98="">{{
                $t("HomePage.dataDetail.videoOut1")
              }}</span></a
            >
            <div data-v-923d6e98="" class="part2-analyse-top-part" style="">
              <div class="video-container">
                <!-- <video controls autoplay loop muted width="100%">
                  <source
                    src="https://cnsknowall.com/media/videos/video1.mp4"
                    type="video/mp4"
                  />
                  您的浏览器不支持 HTML5 video 标签。
                </video> -->
                <a
                  style="display: inline-block"
                  @click.prevent="togglePlay"
                  v-if="imgshow"
                >
                  <img
                    :src="'https://cnsknowall.com/media/videoImgs/imgindex1.png'"
                    alt="加载失败"
                    :style="{
                      height: '415px',
                      width: ' 100%',
                    }"
                  />
                  <i
                    style="
                      position: absolute;
                      display: inline-block;
                      top: 50%;
                      right: 50%;
                      width: 40px;
                    "
                  >
                    <svg
                      t="1713235266551"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="3199"
                      width="50"
                      height="50"
                    >
                      <path
                        d="M713 106.3c78.6 38.3 146.1 100 191.1 175.1 6.9 11.5 13.3 23.4 19.2 35.5 4.3 8.9 14.6 13 23.7 9 9.3-4.1 13.7-15.1 9.2-24.2-6.4-13.3-13.4-26.3-21-38.9-49.5-82.5-120.7-147.7-206.8-189.3-9.2-4.4-20.3-0.1-24.4 9.2-3.9 9 0.1 19.3 9 23.6zM986.5 496c-10 0-18.1 8.1-18.1 18.1 0 249.9-203.3 453.3-453.3 453.3S61.7 764.1 61.7 514.2 265.1 60.9 515 60.9c10 0 18.1-8.1 18.1-18.1S525 24.7 515 24.7c-269.9 0-489.6 219.6-489.6 489.6S245 1003.9 515 1003.9s489.6-219.6 489.6-489.6c0-10.1-8.1-18.3-18.1-18.3z"
                        fill="#1296db"
                        p-id="3200"
                      ></path>
                      <path
                        d="M623.8 60.9m-18.1 0a18.1 18.1 0 1 0 36.2 0 18.1 18.1 0 1 0-36.2 0Z"
                        fill="#1296db"
                        p-id="3201"
                      ></path>
                      <path
                        d="M968.3 405.4m-18.1 0a18.1 18.1 0 1 0 36.2 0 18.1 18.1 0 1 0-36.2 0Z"
                        fill="#1296db"
                        p-id="3202"
                      ></path>
                      <path
                        d="M433 337.9c-9.8 0-19.6 2.6-28.5 7.7C386.6 356 376 374.4 376 395v238.3c0 20.6 10.7 39.1 28.5 49.4 17.9 10.3 39.2 10.3 57 0l206.3-119.1c17.9-10.3 28.5-28.8 28.5-49.4s-10.7-39.1-28.5-49.4L461.5 345.6c-8.9-5.1-18.7-7.7-28.5-7.7z m224.1 176.3c0 3.1-0.9 10.8-8.9 15.4L441.9 648.7c-8 4.6-15.1 1.5-17.8 0-2.7-1.5-8.9-6.1-8.9-15.4V395c0-9.3 6.2-13.9 8.9-15.4 2.7-1.6 9.8-4.6 17.8 0l206.3 119.1c8 4.7 8.9 12.4 8.9 15.5z"
                        fill="#1296db"
                        p-id="3203"
                      ></path></svg
                  ></i>
                </a>
                <video controls autoplay loop muted width="100%" v-else>
                  <source
                    src="https://cnsknowall.com/media/videos/video1.mp4"
                    type="video/mp4"
                  />
                  您的浏览器不支持 HTML5 video 标签。
                </video>
              </div>
              <div data-v-923d6e98="" class="h-section-part2-analyse-list">
                <ul data-v-923d6e98="" class="ulData">
                  <li data-v-923d6e98="">
                    {{ $t("HomePage.dataDetail.lists1.li1") }}
                  </li>
                  <li data-v-923d6e98="" class="checkedActive">
                    {{ $t("HomePage.dataDetail.lists1.li2") }}
                  </li>
                  <li data-v-923d6e98="" icon="icon-BSA1">
                    {{ $t("HomePage.dataDetail.lists1.li3") }}
                  </li>
                  <li data-v-923d6e98="" icon="icon-unicellular1">
                    {{ $t("HomePage.dataDetail.lists1.li4") }}
                  </li>
                  <!-- <li data-v-923d6e98="" icon="icon-metagenome1">
                    微生物宏基因组分析
                  </li>
                  <li data-v-923d6e98="" icon="icon-untargeted">
                    非靶向代谢组分析
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
          <!-- 测试 -->
          <div data-v-923d6e98="" class="h-section-part2-analyse">
            <a
              data-v-923d6e98=""
              href="javascript:void(0)"
              class="part2-analyse-list-more"
              style="text-decoration: none"
              ><span data-v-923d6e98="">{{
                $t("HomePage.dataDetail.videoOut2")
              }}</span></a
            >
            <div data-v-923d6e98="" class="part2-analyse-top-part">
              <div class="video-container">
                <!-- <video controls autoplay loop muted width="100%">
                  <source
                    src="https://cnsknowall.com/media/videos/video2.mp4"
                    type="video/mp4"
                  />
                  您的浏览器不支持 HTML5 video 标签。
                </video> -->
                <a @click.prevent="togglePlay2" v-if="imgshow2">
                  <img
                    :src="'https://cnsknowall.com/media/videoImgs/imgindex2.png'"
                    alt="加载失败"
                    :style="{
                      height: '415px',
                      width: ' 100%',
                    }"
                  />
                  <i
                    style="
                      position: absolute;
                      display: inline-block;
                      top: 50%;
                      right: 50%;
                      width: 40px;
                    "
                  >
                    <svg
                      t="1713235266551"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="3199"
                      width="50"
                      height="50"
                    >
                      <path
                        d="M713 106.3c78.6 38.3 146.1 100 191.1 175.1 6.9 11.5 13.3 23.4 19.2 35.5 4.3 8.9 14.6 13 23.7 9 9.3-4.1 13.7-15.1 9.2-24.2-6.4-13.3-13.4-26.3-21-38.9-49.5-82.5-120.7-147.7-206.8-189.3-9.2-4.4-20.3-0.1-24.4 9.2-3.9 9 0.1 19.3 9 23.6zM986.5 496c-10 0-18.1 8.1-18.1 18.1 0 249.9-203.3 453.3-453.3 453.3S61.7 764.1 61.7 514.2 265.1 60.9 515 60.9c10 0 18.1-8.1 18.1-18.1S525 24.7 515 24.7c-269.9 0-489.6 219.6-489.6 489.6S245 1003.9 515 1003.9s489.6-219.6 489.6-489.6c0-10.1-8.1-18.3-18.1-18.3z"
                        fill="#1296db"
                        p-id="3200"
                      ></path>
                      <path
                        d="M623.8 60.9m-18.1 0a18.1 18.1 0 1 0 36.2 0 18.1 18.1 0 1 0-36.2 0Z"
                        fill="#1296db"
                        p-id="3201"
                      ></path>
                      <path
                        d="M968.3 405.4m-18.1 0a18.1 18.1 0 1 0 36.2 0 18.1 18.1 0 1 0-36.2 0Z"
                        fill="#1296db"
                        p-id="3202"
                      ></path>
                      <path
                        d="M433 337.9c-9.8 0-19.6 2.6-28.5 7.7C386.6 356 376 374.4 376 395v238.3c0 20.6 10.7 39.1 28.5 49.4 17.9 10.3 39.2 10.3 57 0l206.3-119.1c17.9-10.3 28.5-28.8 28.5-49.4s-10.7-39.1-28.5-49.4L461.5 345.6c-8.9-5.1-18.7-7.7-28.5-7.7z m224.1 176.3c0 3.1-0.9 10.8-8.9 15.4L441.9 648.7c-8 4.6-15.1 1.5-17.8 0-2.7-1.5-8.9-6.1-8.9-15.4V395c0-9.3 6.2-13.9 8.9-15.4 2.7-1.6 9.8-4.6 17.8 0l206.3 119.1c8 4.7 8.9 12.4 8.9 15.5z"
                        fill="#1296db"
                        p-id="3203"
                      ></path></svg
                  ></i>
                </a>
                <video controls autoplay loop muted width="100%" v-else>
                  <source
                    src="https://cnsknowall.com/media/videos/video2.mp4"
                    type="video/mp4"
                  />
                  您的浏览器不支持 HTML5 video 标签。
                </video>
              </div>
              <div data-v-923d6e98="" class="h-section-part2-analyse-list">
                <ul data-v-923d6e98="" class="ulData">
                  <li data-v-923d6e98="">
                    {{ $t("HomePage.dataDetail.lists2.li1") }}
                  </li>
                  <li data-v-923d6e98="" class="checkedActive">
                    {{ $t("HomePage.dataDetail.lists2.li2") }}
                  </li>
                  <!-- <li data-v-923d6e98="" icon="icon-BSA1">
                    分组数据颜色随心调整
                  </li> -->
                  <li data-v-923d6e98="" icon="icon-unicellular1">
                    {{ $t("HomePage.dataDetail.lists2.li3") }}
                  </li>
                  <li data-v-923d6e98="" icon="icon-metagenome1">
                    {{ $t("HomePage.dataDetail.lists2.li3") }}
                  </li>
                  <!-- <li data-v-923d6e98="" icon="icon-untargeted">
                    非靶向代谢组分析
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- end -->
      </div>
      <!-- 底部总结 -->
      <div class="">
        <section class="h-section-part3 heightq" style="height: 230px">
          <div class="h-section-part3-usedata"></div>
          <div class="section-part3-user-login">
            <div class="section-part3-login-bg">
              <div
                class="tittt"
                style="
                  padding: 10px;
                  color: #fff;
                  text-align: justify;
                  text-indent: 2rem;
                "
              >
                {{ $t("HomePage.bottomDetails.dataDetail") }}
              </div>
              <div class="section-part3-login-txt" style="color: #eac75c">
                {{ $t("HomePage.bottomDetails.dataText") }}
              </div>
              <div class="" v-if="loginStatic == true">
                <a href="jsvascript:void(0)" @click="jump('BottomContainAll')"
                  ><button
                    type="button"
                    class="ant-btn section-part3-login-button"
                  >
                    <span> {{ $t("HomePage.bottomDetails.login") }}</span>
                  </button></a
                >
              </div>
              <div class="" v-else>
                <a href="jsvascript:void(0)" @click="openModal"
                  ><button
                    type="button"
                    class="ant-btn section-part3-login-button"
                  >
                    <span>{{ $t("HomePage.bottomDetails.NoLogin") }}</span>
                  </button></a
                >
              </div>
            </div>
            <!-- 备案信息 -->
            <div
              class="beian"
              style="
                background: #fff;
                background: rgb(255, 255, 255);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                height: 251px;
                min-width: 1530px;
                overflow-x: auto;
                overflow-y: hidden;
              "
            >
              <ul
                style="
                  display: flex;
                  justify-content: space-evenly;
                  width: 600px;
                "
              >
                <li>
                  <img
                    style="width: 160px; height: 165px"
                    src="https://cnsknowall.com/media/info/img1s.png"
                    alt=""
                  />
                  <div
                    class="bottom1"
                    style="
                      height: 50px;
                      line-height: 50px;
                      border-bottom: 4px solid #ccc;
                    "
                  >
                    {{ $t("HomePage.bottom.book") }}
                  </div>
                </li>
                <li>
                  <img
                    style="width: 160px; height: 165px"
                    src="https://cnsknowall.com/media/info/img2.png"
                    alt=""
                  />
                  <div
                    class="bottom1"
                    style="
                      height: 50px;
                      line-height: 50px;
                      border-bottom: 4px solid #ccc;
                    "
                  >
                    {{ $t("HomePage.bottom.zhihu") }}
                  </div>
                </li>
                <li>
                  <img
                    style="width: 160px; height: 165px"
                    src="https://cnsknowall.com/media/info/img3.png"
                    alt=""
                  />
                  <div
                    class="bottom1"
                    style="
                      height: 50px;
                      line-height: 50px;
                      border-bottom: 4px solid #ccc;
                    "
                  >
                    {{ $t("HomePage.bottom.video") }}
                  </div>
                </li>
              </ul>
              <div class="computer">
                <div class="topp" style="display: flex; width: 406px">
                  <!-- height: 100px;width: 280px; -->
                  <div
                    class="filings1"
                    style="
                      width:406px

                      color: #666;
                      margin-top: -56px;
                    "
                  >
                    <div
                      class="top"
                      style="
                        height: 53px;
                        line-height: 60px;
                        font-size: 18px;
                        font-family: cursive;
                        font-weight: 700;
                      "
                    >
                      {{ $t("HomePage.Company.Cname") }}
                    </div>
                    <div
                      class="bottom"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        src="./img/beian.png"
                        alt=""
                        style="padding-right: 3px"
                      />
                      {{ $t("HomePage.Company.record") }}
                    </div>
                  </div>
                  <!-- <div
                    class="filings1"
                    style="
                      width: 280px;
                      height: 100px;
                      color: #666;
                      margin-top: -56px;
                    "
                  >
                    <div class="top" style="height: 53px; line-height: 60px">
                      上海青罡生物医药技术有限公司
                    </div>
                    <div
                      class="bottom"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        src="./img/beian.png"
                        alt=""
                        style="padding-right: 3px"
                      />
                      沪ICP备2023016389号
                    </div>
                  </div>
                  <div
                    class="filings"
                    style="
                      width: 280px;
                      height: 100px;
                      color: #666;
                      margin-top: -56px;
                    "
                  >
                    <div class="top" style="height: 53px; line-height: 60px">
                      上海百沐生物科技有限公司
                    </div>
                    <div
                      class="bottom"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        src="./img/beian.png"
                        alt=""
                        style="padding-right: 3px"
                      />
                      沪ICP备2023019583号-1
                    </div>
                  </div> -->
                </div>
                <div class="com">
                  <div
                    class="address"
                    style="height: 40px; line-height: 40px; min-width: 257px"
                  >
                    <i
                      ><svg
                        t="1718634694954"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1475"
                        width="24"
                        height="24"
                      >
                        <path
                          d="M821.653333 633.813333L576.533333 917.333333a85.333333 85.333333 0 0 1-129.066666 0.064L198.634667 629.952c-1.152-1.322667-2.133333-2.773333-2.88-4.266667A361.088 361.088 0 0 1 149.333333 448c0-200.298667 162.368-362.666667 362.666667-362.666667s362.666667 162.368 362.666667 362.666667c0 63.744-16.490667 125.162667-47.381334 179.370667a21.269333 21.269333 0 0 1-5.632 6.421333zM792.32 602.453333A318.442667 318.442667 0 0 0 832 448c0-176.725333-143.274667-320-320-320-176.725333 0-320 143.274667-320 320 0 55.317333 14.037333 108.522667 40.384 155.733333l247.317333 285.738667a42.666667 42.666667 0 0 0 64.554667-0.021333L792.32 602.453333zM512 597.333333a149.333333 149.333333 0 1 1 0-298.666666 149.333333 149.333333 0 0 1 0 298.666666z m0-42.666666a106.666667 106.666667 0 1 0 0-213.333334 106.666667 106.666667 0 0 0 0 213.333334z"
                          fill="#222222"
                          p-id="1476"
                        ></path></svg></i
                    >:
                    <span>{{ $t("HomePage.Company.address") }}</span>
                  </div>
                  <div class="postBox" style="margin-right: 3px">
                    <i style="margin-right: 2px"
                      ><svg
                        t="1718634818235"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2589"
                        width="24"
                        height="24"
                      >
                        <path
                          d="M838.954667 234.666667H170.666667c-3.626667 0-7.168 0.448-10.56 1.322666l323.690666 323.669334a21.333333 21.333333 0 0 0 30.165334 0L838.954667 234.666667z m46.144 14.186666l-260.693334 260.693334 262.933334 262.912c5.44-7.168 8.661333-16.106667 8.661333-25.792V277.333333c0-10.944-4.117333-20.906667-10.88-28.48zM843.861333 789.333333l-249.6-249.621333-50.133333 50.133333a64 64 0 0 1-90.517333 0l-50.112-50.133333L156.373333 786.88c4.48 1.578667 9.28 2.453333 14.314667 2.453333h673.194667zM128.661333 754.218667L373.333333 509.525333 129.578667 265.813333A42.709333 42.709333 0 0 0 128 277.333333v469.333334c0 2.56 0.213333 5.098667 0.661333 7.552zM170.666667 192h682.666666a85.333333 85.333333 0 0 1 85.333334 85.333333v469.333334a85.333333 85.333333 0 0 1-85.333334 85.333333H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333333V277.333333a85.333333 85.333333 0 0 1 85.333334-85.333333z"
                          fill="#333333"
                          p-id="2590"
                        ></path></svg></i
                    >:
                    <span>{{ $t("HomePage.Company.email") }}</span>
                  </div>
                </div>
              </div>

              <ul
                style="
                  display: flex;
                  justify-content: space-evenly;
                  width: 600px;
                "
              >
                <li>
                  <img
                    style="width: 160px; height: 165px"
                    src="https://cnsknowall.com/media/info/img4.png"
                    alt=""
                  />
                  <div
                    class="bottom1"
                    style="
                      height: 50px;
                      line-height: 50px;
                      border-bottom: 4px solid #ccc;
                    "
                  >
                    {{ $t("HomePage.bottom.bili") }}
                  </div>
                </li>
                <li>
                  <img
                    style="width: 160px; height: 165px"
                    src="https://cnsknowall.com/media/info/img5.png"
                    alt=""
                  />
                  <div
                    class="bottom1"
                    style="
                      height: 50px;
                      line-height: 50px;
                      border-bottom: 4px solid #ccc;
                    "
                  >
                    {{ $t("HomePage.bottom.BaijiaHao") }}
                  </div>
                </li>
                <li>
                  <img
                    style="width: 160px; height: 165px"
                    src="https://cnsknowall.com/media/info/img6.png"
                    alt=""
                  />
                  <div
                    class="bottom1"
                    style="
                      height: 50px;
                      line-height: 50px;
                      border-bottom: 4px solid #ccc;
                    "
                  >
                    {{ $t("HomePage.bottom.Tiktok") }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- 左侧的悬浮 -->
    <div class="rightIcon"></div>
    <div class="ma">
      <ul class="slide_contain">
        <li
          class="left_item ac_item"
          @click="TojumpOnline('OnlineService', 8)"
          id="colorRed"
          style="margin-bottom: 20px"
        >
          {{ $t("HomePage.slide_contain.service") }}
        </li>

        <li class="left_item ac_item" @click="TojumpOnline('Question', 13)">
          {{ $t("HomePage.slide_contain.questions") }}
        </li>
      </ul>
      <div @mouseover="showQrcode" @mouseout="hideQrcode">
        <div class="right-box">
          <div class="img">
            <!-- 二维码 -->
            <span
              ><svg
                t="1704896837948"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3401"
                width="40"
                height="40"
              >
                <path
                  d="M433.282171 47.939849 94.944396 47.939849c-23.327288 0-42.304502 18.975167-42.304502 42.300408l0 338.341868c0 23.324218 18.977213 42.300408 42.304502 42.300408L433.282171 470.882534c23.324218 0 42.300408-18.97619 42.300408-42.300408L475.582579 90.240257C475.581556 66.915016 456.606389 47.939849 433.282171 47.939849zM438.742533 428.582125c0 3.01159-2.449795 5.461385-5.461385 5.461385L94.944396 434.04351c-3.013637 0-5.465478-2.450818-5.465478-5.461385L89.478917 90.240257c0-3.01159 2.451842-5.461385 5.465478-5.461385L433.282171 84.778872c3.01159 0 5.461385 2.449795 5.461385 5.461385L438.743556 428.582125z"
                  fill="#FF9000"
                  p-id="3402"
                ></path>
                <path
                  d="M322.914504 173.887352l-117.608581 0c-14.733563 0-26.719548 11.985986-26.719548 26.719548l0 117.608581c0 14.735609 11.985986 26.724665 26.719548 26.724665l117.608581 0c14.735609 0 26.724665-11.989055 26.724665-26.724665l0-117.608581C349.640192 185.873338 337.651137 173.887352 322.914504 173.887352zM312.801169 308.101123l-97.374748 0 0-97.374748 97.374748 0L312.801169 308.101123z"
                  fill="#FF9000"
                  p-id="3403"
                ></path>
                <path
                  d="M322.914504 675.606195l-117.608581 0c-14.733563 0-26.719548 11.989055-26.719548 26.724665l0 117.608581c0 14.732539 11.985986 26.719548 26.719548 26.719548l117.608581 0c14.735609 0 26.724665-11.987009 26.724665-26.719548l0-117.608581C349.640192 687.59525 337.651137 675.606195 322.914504 675.606195zM312.801169 809.819966l-97.374748 0 0-97.374748 97.374748 0L312.801169 809.819966z"
                  fill="#FF9000"
                  p-id="3404"
                ></path>
                <path
                  d="M699.123818 344.940147l117.608581 0c14.735609 0 26.724665-11.989055 26.724665-26.724665l0-117.608581c0-14.733563-11.989055-26.719548-26.724665-26.719548l-117.608581 0c-14.732539 0-26.719548 11.985986-26.719548 26.719548l0 117.608581C672.40427 332.951091 684.391279 344.940147 699.123818 344.940147zM709.243293 210.726376l97.374748 0 0 97.374748-97.374748 0L709.243293 210.726376z"
                  fill="#FF9000"
                  p-id="3405"
                ></path>
                <path
                  d="M433.282171 546.435277 94.944396 546.435277c-23.327288 0-42.304502 18.97619-42.304502 42.300408L52.639894 927.077553c0 23.325241 18.977213 42.301432 42.304502 42.301432L433.282171 969.378985c23.324218 0 42.300408-18.97619 42.300408-42.301432L475.582579 588.735685C475.581556 565.411467 456.606389 546.435277 433.282171 546.435277zM438.742533 927.077553c0 3.01159-2.449795 5.462408-5.461385 5.462408L94.944396 932.539962c-3.013637 0-5.465478-2.450818-5.465478-5.462408L89.478917 588.735685c0-3.010567 2.451842-5.461385 5.465478-5.461385L433.282171 583.2743c3.01159 0 5.461385 2.450818 5.461385 5.461385L438.743556 927.077553z"
                  fill="#FF9000"
                  p-id="3406"
                ></path>
                <path
                  d="M590.849836 547.776831c-23.325241 0-42.301432 18.978237-42.301432 42.305525l0 194.649212c0 10.172687 8.246825 18.419512 18.419512 18.419512s18.419512-8.246825 18.419512-18.419512L585.387427 590.082356c0-3.013637 2.450818-5.466502 5.462408-5.466502l204.825992 0c10.172687 0 18.419512-8.246825 18.419512-18.419512s-8.246825-18.419512-18.419512-18.419512L590.849836 547.776831z"
                  fill="#FF9000"
                  p-id="3407"
                ></path>
                <path
                  d="M588.757175 470.882534l338.341868 0c23.325241 0 42.301432-18.97619 42.301432-42.300408L969.400474 90.240257c0-23.324218-18.97619-42.300408-42.301432-42.300408L588.757175 47.939849c-23.324218 0-42.300408 18.975167-42.300408 42.300408l0 338.341868C546.456766 451.906343 565.432956 470.882534 588.757175 470.882534zM583.295789 90.240257c0-3.01159 2.450818-5.461385 5.461385-5.461385l338.341868 0c3.01159 0 5.462408 2.449795 5.462408 5.461385l0 338.341868c0 3.01159-2.450818 5.461385-5.462408 5.461385L588.757175 434.04351c-3.010567 0-5.461385-2.450818-5.461385-5.461385L583.295789 90.240257z"
                  fill="#FF9000"
                  p-id="3408"
                ></path>
                <path
                  d="M969.676767 566.196343c0-10.172687-8.246825-18.419512-18.419512-18.419512l-67.50444 0c-10.172687 0-18.419512 8.246825-18.419512 18.419512s8.246825 18.419512 18.419512 18.419512l49.084928 0 0 71.009264L779.708158 655.625118c-10.172687 0-18.419512 8.246825-18.419512 18.419512s8.246825 18.419512 18.419512 18.419512l171.549098 0c10.172687 0 18.419512-8.246825 18.419512-18.419512 0-0.076748-0.010233-0.151449-0.011256-0.228197 0.001023-0.076748 0.011256-0.150426 0.011256-0.228197L969.676767 566.196343z"
                  fill="#FF9000"
                  p-id="3409"
                ></path>
                <path
                  d="M630.202099 933.142689l-44.813648 0 0-51.2922c0-10.172687-8.246825-18.419512-18.419512-18.419512s-18.419512 8.246825-18.419512 18.419512l0 69.711711c0 10.172687 8.246825 18.419512 18.419512 18.419512l63.23316 0c10.172687 0 18.419512-8.246825 18.419512-18.419512S640.374786 933.142689 630.202099 933.142689z"
                  fill="#FF9000"
                  p-id="3410"
                ></path>
                <path
                  d="M834.04981 790.359752c0-10.172687-8.246825-18.419512-18.419512-18.419512L699.257871 771.940241c-10.172687 0-18.419512 8.246825-18.419512 18.419512l0 163.013701c0 10.172687 8.246825 18.419512 18.419512 18.419512s18.419512-8.246825 18.419512-18.419512L717.677383 808.779264 815.630299 808.779264C825.802986 808.779264 834.04981 800.532439 834.04981 790.359752z"
                  fill="#FF9000"
                  p-id="3411"
                ></path>
                <path
                  d="M951.060781 723.425294c-10.172687 0-18.419512 8.246825-18.419512 18.419512l0 194.301288L765.943666 936.146093c-10.172687 0-18.419512 8.246825-18.419512 18.419512s8.246825 18.419512 18.419512 18.419512l185.117115 0c10.172687 0 18.419512-8.246825 18.419512-18.419512L969.480292 741.844805C969.480292 731.672118 961.234491 723.425294 951.060781 723.425294z"
                  fill="#FF9000"
                  p-id="3412"
                ></path>
                <path
                  d="M655.979182 623.620194c-10.172687 0-18.419512 8.246825-18.419512 18.419512l0 59.869552c0 10.172687 8.246825 18.419512 18.419512 18.419512s18.419512-8.246825 18.419512-18.419512l0-59.869552C674.398694 631.867018 666.151869 623.620194 655.979182 623.620194z"
                  fill="#FF9000"
                  p-id="3413"
                ></path></svg
            ></span>
          </div>
          <div class="sao">{{ $t("HomePage.slide_contain.scan") }}</div>
        </div>
        <div
          class="qrcode"
          :style="{ display: qrcodeVisible ? 'block' : 'none' }"
        >
          <img style="height: 100%" src="./img/qrcode2.jpg" alt="" />
          <!-- <qrcode-vue :text="paymentLink" :size="80"></qrcode-vue>
        <div class="txt">关注公众号</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import { Loading, Message } from "element-ui";
// import bus from "@/utils/bus.js";
export default {
  components: {
    VueCountdown,
  },
  data() {
    return {
      name: 'TimerComponent',
      // 语言
      options: [
        {
          value: "zh",
          label: "中文",
        },
        {
          value: "en",
          label: "English",
        },
      ],
      value: "zh",
      activeNames: "1",
      chartsList: [],
      imgshow: true,
      imgshow2: true,
      // 通知公告的分类
      NewParts: null,
      // 新图
      NewImgs: null,
      // 新图中的主要内容
      NewImgsParts: null,
      // 消息内容
      NewsBody: null,
      // 转码后的
      convertedHTML: "",
      // 滚动字幕
      subtitle:
        "耗资上千万搭建的全国最大的在线科研数据（统计学+生物信息学）分析云平台，彻底颠覆和变革传统数据分析工具和分析模式，只为带给您前所未有的数据分析体验!   每月持续上新30个可视化图表工具，涵盖全领域分析方法，将数据分析的便捷度提高到前所有未的高度！耗资上千万搭建的全国最大的",
      animationDuration: "35s", // Adjust the animation duration as needed
      //
      SVIPNum: null,
      // 是否抖动
      isShaking: true,
      // 文字轮播
      texts: [
        "数据分析从未如此神清气爽，不需要费力学习代码也能1分钟内轻松画出CNS级别高分文章图表，替代并超越R语言",
        "很多时候知道画什么图往往比会画什么图更重要，一次性收藏500个画图工具，找到匹配图表，最大化挖掘数据背后的价值和意义",
        "CNSKnowall专注科研数据分析，致力于帮助广大科研工作者用最小的成本画出任何自己想要的图表",
        "变革传统数据分析模式和流程，一键完成各种图表可视化，让天下没有难做的科研数据分析",
      ],
      currentIndex: 0,
      // 登录参数
      showPopup: true,
      url: "",
      targetURL: "",
      codemesage: true,
      phoneNumber: "",
      phoneCode: "",
      // 验证码参数s
      counting: false,
      // log_off: false //退出登录标志，如果为true就不自动登录
      //
      qrcodeVisible: false,
      qrcodeVisible1: false,
      witchVIP: null,
      // // 二维码显示
      // qrcodeShow:false,
      // 二维码
      paymentLink: "",
      bgc_img: [
        "https://gcloud-public.oss-cn-shanghai.aliyuncs.com/HomePage/homepage_banner/READS.webp",
        "https://gcloud-public.oss-cn-shanghai.aliyuncs.com/HomePage/homepage_banner/microbial_community.webp",
        "	https://gcloud-public.oss-cn-shanghai.aliyuncs.com/HomePage/homepage_banner/untargeted.webp",
        "	https://public.genescloud.cn/HomePage/homepage_banner/ChartOverview.webp",
      ],
      // 是否登录的标识
      loginStatic: false,
      userPhotoUrl: null,
      logindata: null,
      userPhotoUrl: null,
      VipNum: null,
      // 模态框的数据
      modalOpen: false,
      isDragging: false,
      modalOpen1: false,
      isDragging1: false,
      mouseX: 0,
      mouseY: 0,
      modalX: 0,
      modalY: 0,
      offsetX: 0,
      offsetY: 0,
      //首页轮播图图片数据
      imgOne: [
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg1.png",
          name: "Home/Pie1Detail?pid=1206004",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg2.png",
          name: "Home/ZhuDetail?pid=1202004",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg3.png",
          name: "Home/GroupingHeatMap?pid=20201002",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg4.png",
          name: "Home/BasicsHeatMap?pid=20201005",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg5.png",
          name: "Home/VennPieDetail?pid=20401012",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg6.png",
          name: "Home/PieBar?pid=1303001",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg7.png",
          name: "Home/manyBars?pid=1303008",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg8.png",
          name: "Home/annularHeatMap?pid=20201010",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg9.png",
          name: "Home/PairTestBoxplots?pid=20601015",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg10.png",
          name: "Home/GradientVolcano?pid=20601025",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg11.png",
          name: "Home/DonutsSampleDistribution?pid=21101001",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg12.png",
          name: "Home/ChordBarDetail?pid=20701013",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg13.png",
          name: "Home/manyHeatMaps?pid=20201009",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg14.png",
          name: "Home/MatrixPie?pid=1201012",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg15.png",
          name: "Home/BarStackedChartDetail?pid=1301005",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg16.png",
          name: "Home/TwoSides?pid=1302006",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg17.png",
          name: "Home/stackAndPercentBar?pid=1301002",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg18.png",
          name: "Home/TruncatedStackedBarChart?pid=1301006",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg19.png",
          name: "Home/ClassifyBar?pid=20701002",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg20.png",
          name: "Home/Sangji1Detail?pid=1501001",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg41.png",
          name: "Home/MatrixBoxPlot?pid=1601005",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg43.png",
          name: "Home/MultigroupVolcanoPlot?pid=20601008",
        },

        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg45.png",
          name: "Home/ChordEnrichmentDetail?pid=20701012",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg46.png",
          name: "Home/BandErrorMap?pid=20601036",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg47.png",
          name: "Home/HalfBoxDiagram?pid=20601054",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg48.png",
          name: "Home/survivalCurve?pid=20601010",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg49.png",
          name: "Home/GroupedLinearRegression?pid=20901009",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg1.png",
          name: "Home/Pie1Detail?pid=1206004",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg2.png",
          name: "Home/ZhuDetail?pid=1202004",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg3.png",
          name: "pHome/GroupingHeatMap?pid=20201002",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg4.png",
          name: "Home/BasicsHeatMap?pid=20201005",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg5.png",
          name: "Home/VennPieDetail?pid=20401012",
        },
      ],
      imgTwo: [
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg44.png",
          name: "Home/pairedDumbbell?pid=20601028",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg50.png",
          name: "Home/TimeSeriesCurve?pid=20601048",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg51.png",
          name: "Home/DensitySquare?pid=20301015",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg52.png",
          name: "Home/BasicsHeatMap?pid=20201001",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg53.png",
          name: "Home/Pie3DBarDetail?pid=1201015",
        },

        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg21.png",
          name: "Home/MapBubbleDetail?pid=1803001",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg22.png",
          name: "Home/PieWienRatioDetail?pid=20401003",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg23.png",
          name: "Home/DifferenceBar?pid=20601001",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg24.png",
          name: "Home/SankeyBubbleDiagram?pid=20701008",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg25.png",
          name: "Home/BubbleArithmeticProgression?pid=20201019",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg26.png",
          name: "Home/TreeBar?pid=21801004",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg27.png",
          name: "UMAP?pid=330501001",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg28.png",
          name: "Home/MatrixLinearRegression?pid=20901007",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg29.png",
          name: "Home/MultiRowViolin?pid=20301004",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg30.png",
          name: "Home/ComboLineColumn?pid=20601019",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg31.png",
          name: "Home/NetWorkVennDetail?pid=20401006",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg32.png",
          name: "Home/GroupHeatMap?pid=20201014",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg33.png",
          name: "Home/ThreeWireChart?pid=1701004",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg34.png",
          name: "Home/FlowerVennDetail?pid=20401005",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg35.png",
          name: "Home/BoundaryPCA?pid=20801007",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg36.png",
          name: "Home/groupMarixPie?pid=1201017",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg37.png",
          name: "Home/BarPercentageDetail?pid=1301014",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg38.png",
          name: "Home/AreaHeatmapDetail?pid=1204006",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg39.png",
          name: "Home/LineError?pid=1403005",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg40.png",
          name: "Home/VolcanoPlot?pid=20601004",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg42.png",
          name: "Home/ProvinceThermalMap?pid=1804002",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg44.png",
          name: "Home/pairedDumbbell?pid=20601028",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg50.png",
          name: "Home/TimeSeriesCurve?pid=20601048",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg51.png",
          name: "Home/DensitySquare?pid=20301015",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg52.png",
          name: "Home/BasicsHeatMap?pid=20201001",
        },
        {
          imgurl: "https://cnsknowall.com/media/HomePageImg/homeImg53.png",
          name: "Home/Pie3DBarDetail?pid=1201015",
        },
      ],
    };
  },
  created() {
    this.beforeRouteUpdate();
  },
  computed: {
    currentText() {
      return this.$t("HomePage.dataDetail.TurnTexts")[this.currentIndex];
    },
  },
  mounted() {
    console.log(this.$t("HomePage.nave_b.third.text"));
    this.isShaking = true;
    // 文字轮播
    setInterval(() => {
      this.currentIndex =
        (this.currentIndex + 1) %
        this.$t("HomePage.dataDetail.TurnTexts").length;
    }, 5000); // 每3秒更新一次
    //
    this.loginAgain();
    // this.$nextTick(() => {
    // 更换新的登录
    this.targetURL =
      "https://open.weixin.qq.com/connect/qrconnect?appid=wx97dc2ccc715c8d87&redirect_uri=" +
      encodeURIComponent(
        "https://cnsknowall.com/api/WeChatLogin?back=" +
          encodeURIComponent(location.href)
      ) +
      "&response_type=code&scope=snsapi_login&state=STATE";
    // console.log(this.targetURL);
    // });
  },
  methods: {
    // switchLanguage1() {
    //   this.$switchLanguage();
    // },
    TojumpOnline(name, index) {
      const { href } = this.$router.resolve({
        name,
      });
      window.open(href, "_blank");
    },
    // ToChart(name) {
    //   this.$router.push(name);
    // console.log(name);
    // const { href } = this.$router.resolve({
    //   name,
    // });
    // window.open(href, "_blank");
    // },
    handleChange(val) {
      console.log(val, typeof val);
      this.getNewBody(val);
    },
    togglePlay() {
      this.imgshow = false;
      console.log(666, this.imgshow);
    },
    togglePlay2() {
      this.imgshow2 = false;
      console.log(666, this.imgshow2);
    },
    // 获得公告分类
    async getNewparts() {
      let formData = new FormData();
      // formData.append("keyword", queryString);
      const newImgs = await this.$api.getNews();
      this.NewParts = newImgs;
      console.log(newImgs);
      this.getNewImg();
    },
    // 获得新图上线提醒
    async getNewImg() {
      let formData = new FormData();
      // console.log(this.NewParts.length);

      formData.append("cid", this.NewParts[1].id);
      const newImgs = await this.$api.getNews(formData);
      console.log(newImgs);
      this.NewImgsParts = newImgs;
      this.chartsList = newImgs;
      if (this.NewImgsParts.data[0].status == 1) {
        this.getNewBody();
      }
      this.setTimer()
    },
    setTimer() {
      const lastCall = localStorage.getItem('lastCall'); // 获取上次调用的时间
      const currentTime = new Date().getTime(); // 获取当前时间戳
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 一天的毫秒数

      if (!lastCall || currentTime - lastCall > oneDayInMilliseconds) {
        // 如果没有记录上次调用时间，或者距离上次调用超过一天，执行计时器操作
        // console.log('Timer triggered!');
        localStorage.setItem('lastCall', currentTime); // 更新调用时间
        this.getNewBody();
      } else {
        console.log('Already triggered today.');
      }
    },
    // 获得新图上线公告中的某一类的内容提醒
    async getNewBody(idNum) {
      let formData = new FormData();
      console.log(idNum, typeof idNum, typeof idNum == "string");
      if (idNum && idNum - 1 >= 0) {
        formData.append("id", this.NewImgsParts.data[idNum - 1].id);
      } else {
        formData.append("id", this.NewImgsParts.data[0].id);
      }
      const newImgs = await this.$api.getNews(formData);
      console.log(newImgs);
      //
      this.NewsBody = newImgs.body;
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.NewsBody;

      // 获取解码后的 HTML
      const convertedHTML = tempDiv.textContent || tempDiv.innerText;
      // console.log(convertedHTML);
      // 在控制台输出解码后的 HTML

      this.convertedHTML = convertedHTML.replace(
        /<img/g,
        '<img class="images1"'
      );
      // console.log(this.convertedHTML);
      // console.log(document.querySelectorAll("p"));
      this.$nextTick(() => {
        // console.log(document.querySelectorAll("p"));
        const imglist = document.querySelectorAll("p");
        // console.log(imglist);

        // 遍历集合并控制样式
        imglist.forEach(function (pElement) {
          // console.log(pElement);
          // 添加或修改样式属性
          pElement.style.height = "100%"; // 修改文字颜色为红色
          pElement.style.overflow = "auto"; // 修改字体大小为 16 像素
          pElement.style.overflowX = "hiden";
          pElement.style.textAlign = "center"; // 修改字体加粗
          pElement.style.marginTop = "10px"; // 修改上边距为 10 像素
        });
      });
      if (this.NewsBody != null) {
        this.openModal1();
      }
    },

    // 验证码方法
    startCountdown: function () {
      this.counting = true;
      this.phoneNumber = this.$refs.inputphone.value;
      // console.log(this.phoneNumber);
      let formData = new FormData();
      formData.append("phoneNumber", this.phoneNumber);

      // const phoneReg = /(^1[34578]\d{9}$)/;
      const phoneReg =
        /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|192|198|199|(147))\d{8}$/;
      if (phoneReg.test(this.phoneNumber)) {
        this.axios
          .post("https://cnsknowall.com/api/sendSmsVerification", formData, {
            withCredentials: true,
          })
          .then((response) => {
            // console.log(response.data); //后台返回的数据

            this.$message({
              showClose: true,
              message: "发送成功",
              type: "success",
            });
          })
          .catch(() => {
            // console.log(error.response.data)
            this.$message({
              showClose: true,
              message: "发送失败",
              type: "error",
            });
          });
      } else {
        this.$message({
          showClose: true,
          message: "您输入的不是一个合法的手机号",
          type: "error",
        });
      }
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
    // end
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    closePopup() {
      this.showPopup = false;
    },

    getPhoneCode() {
      this.phoneCode = this.$refs.phoneCode.value;
      // console.log(this.phoneCode);
    },
    loginUp() {
      let formData = new FormData();
      formData.append("code", this.phoneCode);
      formData.append("phoneNumber", this.phoneNumber);
      this.axios
        .post("https://cnsknowall.com/api/LoginWithPhoneNumber", formData, {
          withCredentials: true,
          //  headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        })
        .then((response) => {
          // console.log(response.data); //后台返回的数据

          if (response.data.code === 1) {
            // bus.$emit('send',response.data.data.userid)

            // localStorage.setItem("user_id", response.data.data.userid);

            sessionStorage.setItem("default-active", "1-1");
            // localStorage.setItem("aside_index", '1-1');
            sessionStorage.setItem("activeIndex", 1);
            this.$message({
              showClose: true,
              message: "已上传成功",
              type: "success",
            });
            top.location.href = this.url;
            // this.$router.push({
            //   name: "HomePage",
            // });
          }
        })
        .catch(() => {
          // console.log(error.response.data)
          this.$message({
            showClose: true,
            message: "上传失败",
            type: "error",
          });
        });
      //  bus.$emit('send',6666)
      //  this.$router.push({
      //         name: "BottomContainAll",
      //       });
    },
    //
    // 登录模态框的方法
    openModal1() {
      // if (this.NewsBody != null) {
      this.modalOpen1 = true;
      // this.getwitchVIP();
      this.$nextTick(() => {
        this.centerModal();
      });
      // }
    },
    closeModal1() {
      this.modalOpen1 = false;
      // 退出后，清除定时器
      // this.$message({
      //   showClose: true,
      //   message: "取消支付",
      //   type: "warning",
      // });
    },
    openModal() {
      // console.log(666);
      this.modalOpen = true;
      this.$nextTick(() => {
        this.centerModal();
      });
    },
    closeModal() {
      this.modalOpen = false;
    },
    startDrag(event) {
      this.isDragging = true;
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      const modalContent = this.$refs.modalContent;
      const modalRect = modalContent.getBoundingClientRect();
      // console.log(modalContent);

      this.offsetX = this.mouseX - modalRect.left;
      this.offsetY = this.mouseY - modalRect.top;
      document.addEventListener("mousemove", this.dragModal);
      document.addEventListener("mouseup", this.stopDrag);
    },
    dragModal(event) {
      if (!this.isDragging) return;
      const newX = event.clientX - this.offsetX;
      const newY = event.clientY - this.offsetY;
      this.modalX = newX;
      this.modalY = newY;
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.dragModal);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    centerModal() {
      const modalContent = this.$refs.modalContent;
      const modalRect = modalContent.getBoundingClientRect();
      const windowWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const modalWidth = modalRect.width;
      const modalHeight = modalRect.height;
      this.modalX = (windowWidth - modalWidth) / 2;
      this.modalY = (windowHeight - modalHeight) / 2;
    },
    beforeRouteUpdate(to, from, next) {
      // 在路由更新时执行的逻辑
      // console.log("首页路由更新了");
      // 可以在这里执行你的逻辑，比如更新数据或触发事件
      // next();
    },
    showQrcode() {
      this.qrcodeVisible = true;
    },
    hideQrcode() {
      this.qrcodeVisible = false;
    },

    // 请求二维码（这个接口切换就行了
    // async getVipCodeData() {
    //   try {

    //     // 2.1 发起获取支付二维码请求
    //     let formData = new FormData();
    //     formData.append("pay_type", 1);
    //     // formData.append("bid", this.witchVIP);
    //     console.log(this.witchVIP);
    //     let Cell6Data = await this.axios.post(
    //       "https://cnsknowall.com/OnlinePay/start",
    //       formData,
    //       {
    //         withCredentials: true,
    //       }
    //     );
    //     console.log(Cell6Data);
    //     this.paymentLink = Cell6Data.data.data;
    //   } catch (error) {
    //     console.error("获取支付二维码数据出错", error);
    //   }
    // },

    // 退出cookie
    removeCookie() {
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        for (var i = keys.length; i--; ) {
          document.cookie =
            keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString();
          document.cookie =
            keys[i] +
            "=0;path=/;domain=.cnsknowall.com;expires=" +
            new Date(0).toUTCString();
        }
      }
    },
    // 退出登录
    log_off() {
      localStorage.clear();
      // 清除名为 "token" 的Cookie
      this.removeCookie();

      // localStorage.setItem("log_off", true);
      this.$router.push({
        name: "HomePage",
      });
      var loadinginstace = Loading.service({
        lock: true,
        text: "正在退出登录，请稍后。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      setTimeout(() => {
        window.location.reload(); // 刷新页面
        // loadinginstace.close();

        //要执行的任务
      }, 500); //延迟1秒执行任务
      // window.location.reload() // 刷新页面
    },
    // 数据分析
    jump2(name) {
      localStorage.setItem("NavTopIndex", 2);
      const { href } = this.$router.resolve({
        name,
      });
      window.open(href, "_blank");
      // this.$router.push({
      //   name,
      // });
    },
    // 去个人中心
    Tojump(name, index) {
      // if (index < 3 || index == null) {
      localStorage.setItem("NavTopIndex", 1);
      this.activeIndex = index;
      // const { href } = this.$router.resolve({
      //   name,
      // });
      // window.open(href, "_blank");
      const { href } = this.$router.resolve({
        name,
      });
      window.open(href, "_blank");
      // console.log(index);
      // } else {
      //   // this.activeIndex = index;
      //   // localStorage.setItem("NavTopIndex", index);
      //   // console.log(index);
      // }
    },
    // 检查是否已经登录如果已经登录就自动登录
    loginAgain() {
      let formData = new FormData();
      // 获取当前页面的 URL
      const currentUrl = window.location.href;
      // console.log(currentUrl);
      // 对 URL 进行编码
      const encodedUrl = encodeURIComponent(currentUrl);
      // formData.append("code", this.phoneCode);
      // formData.append("phoneNumber", this.phoneNumber);
      this.axios
        .post("https://cnsknowall.com/api/islogin?p=" + encodedUrl, {
          withCredentials: true,
        })
        .then((response) => {
          // console.log(response.data); //后台返回的数据
          if (response.data.islogin === true) {
            this.loginStatic = true;

            // 个人信息
            if (response.data.user.nickname == null) {
              this.logindata = response.data.user.userid;
            } else {
              this.logindata = response.data.user.nickname;
              this.userPhotoUrl = response.data.user.headimgurl;
            }
            if (response.data.user.vip == 0) {
              this.VipNum = false;
            } else if (response.data.user.vip == 1) {
              this.VipNum = true;
            } else if (response.data.user.vip == 2) {
              this.SVIPNum = response.data.user.vip;
              // console.log(response.data.user.vip, this.SVIPNum);
            } else {
              // console.log(666);
            }
            this.getNewparts();
            this.$message({
              showClose: true,
              message: "您已登录",
              type: "success",
            });
            // this.$router.push({
            //   name: "BottomContainAll",
            // });
          }
        })
        .catch(() => {
          // console.log(error.response.data);
          this.$message({
            showClose: true,
            message: "请重新登陆",
            type: "error",
          });
        });
    },
    toLogin() {
      this.$router.push({
        name: "Login",
      });
    },
    jump(path) {
      // if (this.loginStatic == true) {
      this.$router.push({
        name: path,
      });
      // }
    },
  },
  watch: {
    // value(oldval, newvalue) {
    //   console.log(oldval, newvalue);
    //   this.$i18n.locale = newvalue;
    //   this.$switchLanguage();
    // },
  },
};
</script>
<style src="@/assets/CSS/LoginCss.css"  scoped>
</style>
<style lang="scss" scoped>
#mooo {
  margin-top: 0 !important;
}
a {
  text-decoration: none; /*去掉下划线*/
}
// ::v-deep .el-carousel__container {
//   background-image: url(https://pic.616pic.com/bg_w1180/00/00/49/6DvLdLKioU.jpg);
// }
::v-deep .el-carousel__item.is-animating {
  // background-image: url(https://pic.616pic.com/bg_w1180/00/00/49/6DvLdLKioU.jpg);
  // 2,3,5,6
}

.ulData {
  // height: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
}
.yes {
  color: green;
}
ul li {
  list-style: none;
}
.home {
  min-width: 1332px;
  .slide_contain {
    // position: fixed;
    z-index: 1000;
    // top: 50%;
    // right: 36px;
    // padding: 30px 10px 10px;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 58px;
    /* background: #ffffff; */
    /* box-shadow: 0 10px 18px 2px rgba(187, 188, 195, 0.1490196078); */
    border-radius: 10px;
    box-sizing: border-box;
    // transform: translateY(-50%);
    li {
      border-radius: 4px;
      padding: 5px 11px;
      /* margin-bottom: 20px; */
      letter-spacing: 3px;
      /* line-height: 14px; */
      font-size: 14px;
      text-align: center;
      background: #186078;
      color: #fff;
      cursor: pointer;
    }
    li:hover {
      color: #81d8d0;
    }
  }
  .header-nav-bar {
    white-space: nowrap;
    display: flex;
    background: #f5f5f5;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // min-width: 1400px;
    min-width: 1332px;
    height: 60px;
    // line-height: 60px;
    padding-left: 20px;
    padding-right: 30px;
    z-index: 99;
    .nav-bar-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1600px;
      .bar-block-left {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        width: 974px;
        .block-left-link {
          display: block;
          // height: 60px;
          height: 86px;
          line-height: 60px;
          .block-left-white {
            color: #1d6af2;
          }
        }
        .block-left-module {
          // margin-left: 30px;
          .left-module-link {
            margin: 0 8px;
            // font-size: 15px;
            // color: #000;
            color: #505050;
            font-size: 16px;
            font-weight: 700;
            .MoneyHot {
              position: absolute;
              // background-image: linear-gradient(#ff1f1f, #ff7878);
              top: -18px;
              right: -21px;
              // font-size: 12px;
              // padding: 0 5px;
              // line-height: 19px;
              // color: #fff;
              // border-radius: 6px;
              // opacity: 0.8;
            }
          }
        }
      }
      .scrolling-container {
        width: 200px;
        height: 30px; /* Adjust the height as needed */
        overflow: hidden;
        position: relative;
        background-color: #333;
        // background-color: #ccc;
        margin-right: 10px;
        color: #fff;
      }

      .scrolling-text {
        line-height: 30px;
        white-space: nowrap;
        position: absolute;
        animation: scrolling linear infinite;
      }

      @keyframes scrolling {
        from {
          // transform: translateX(6%);
          transform: translateX(0%);
        }
        to {
          transform: translateX(-1805px);
        }
      }
      // 文字轮播

      // .el-carousel__item
      .bar-block-right {
        width: 258px;
        .login {
          // margin-right: 20px;
          display: flex;
          align-items: center;
          color: blue;
          opacity: 1;
          white-space: nowrap;
        }
        .login_user {
          .ant-menu {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5;
            -webkit-font-feature-settings: "tnum", "tnum";
            font-feature-settings: "tnum", "tnum";
            margin-bottom: 0;
            padding-left: 0;
            color: rgba(0, 0, 0, 0.65);
            line-height: 0;
            list-style: none;
            background: #fff;
            outline: none;
            -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            -webkit-transition: background 0.3s, width 0.2s;
            transition: background 0.3s, width 0.2s;
            zoom: 1;
          }

          .ant-menu::before,
          .ant-menu::after {
            display: table;
            content: "";
          }

          .ant-menu::after {
            clear: both;
          }

          .ant-menu ul,
          .ant-menu ol {
            margin: 0;
            padding: 0;
            list-style: none;
          }

          .ant-menu-hidden {
            display: none;
          }

          .ant-menu-item-group-title {
            padding: 8px 16px;
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
            line-height: 1.5;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
          }

          .ant-menu-submenu,
          .ant-menu-submenu-inline {
            -webkit-transition: border-color 0.3s
                cubic-bezier(0.645, 0.045, 0.355, 1),
              background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          .ant-menu-submenu-selected {
            color: #1890ff;
          }

          .ant-menu-item:active,
          .ant-menu-submenu-title:active {
            background: #e6f7ff;
          }

          .ant-menu-submenu .ant-menu-sub {
            cursor: auto;
            cursor: initial;
            -webkit-transition: background 0.3s
                cubic-bezier(0.645, 0.045, 0.355, 1),
              padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          .ant-menu-item > a {
            display: block;
            color: rgba(0, 0, 0, 0.65);
          }

          .ant-menu-item > a:hover {
            color: #1890ff;
          }

          .ant-menu-item > a::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: transparent;
            content: "";
          }

          .ant-menu-item > .ant-badge > a {
            color: rgba(0, 0, 0, 0.65);
          }

          .ant-menu-item > .ant-badge > a:hover {
            color: #1890ff;
          }

          .ant-menu-item-divider {
            height: 1px;
            overflow: hidden;
            line-height: 0;
            background-color: #e8e8e8;
          }

          .ant-menu-item:hover,
          .ant-menu-item-active,
          .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
          .ant-menu-submenu-active,
          .ant-menu-submenu-title:hover {
            color: #1890ff;
          }

          .ant-menu-horizontal .ant-menu-item,
          .ant-menu-horizontal .ant-menu-submenu {
            margin-top: -1px;
          }

          .ant-menu-horizontal > .ant-menu-item:hover,
          .ant-menu-horizontal > .ant-menu-item-active,
          .ant-menu-horizontal
            > .ant-menu-submenu
            .ant-menu-submenu-title:hover {
            background-color: transparent;
          }

          .ant-menu-item-selected {
            color: #1890ff;
          }

          .ant-menu-item-selected > a,
          .ant-menu-item-selected > a:hover {
            color: #1890ff;
          }

          .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: #e6f7ff;
          }

          .ant-menu-inline,
          .ant-menu-vertical,
          .ant-menu-vertical-left {
            border-right: 1px solid #e8e8e8;
          }

          .ant-menu-vertical-right {
            border-left: 1px solid #e8e8e8;
          }

          .ant-menu-vertical.ant-menu-sub,
          .ant-menu-vertical-left.ant-menu-sub,
          .ant-menu-vertical-right.ant-menu-sub {
            min-width: 160px;
            padding: 0;
            border-right: 0;
            -webkit-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
            transform-origin: 0 0;
          }

          .ant-menu-vertical.ant-menu-sub .ant-menu-item,
          .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
          .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
            left: 0;
            margin-left: 0;
            border-right: 0;
          }

          .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
          .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
          .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
            border-right: 0;
          }

          .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
          .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
          .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
          .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
          .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
          .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
            -webkit-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
            transform-origin: 0 0;
          }

          .ant-menu-horizontal.ant-menu-sub {
            min-width: 114px;
          }

          .ant-menu-item,
          .ant-menu-submenu-title {
            position: relative;
            display: block;
            margin: 0;
            padding: 0 20px;
            white-space: nowrap;
            cursor: pointer;
            -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          .ant-menu-item .anticon,
          .ant-menu-submenu-title .anticon {
            min-width: 14px;
            margin-right: 10px;
            font-size: 14px;
            -webkit-transition: font-size 0.15s
                cubic-bezier(0.215, 0.61, 0.355, 1),
              margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
              margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          .ant-menu-item .anticon + span,
          .ant-menu-submenu-title .anticon + span {
            opacity: 1;
            -webkit-transition: opacity 0.3s
                cubic-bezier(0.645, 0.045, 0.355, 1),
              width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          .ant-menu > .ant-menu-item-divider {
            height: 1px;
            margin: 1px 0;
            padding: 0;
            overflow: hidden;
            line-height: 0;
            background-color: #e8e8e8;
          }

          .ant-menu-submenu-popup {
            position: absolute;
            z-index: 1050;
            background: #fff;
            border-radius: 4px;
          }

          .ant-menu-submenu-popup .submenu-title-wrapper {
            padding-right: 20px;
          }

          .ant-menu-submenu-popup::before {
            position: absolute;
            top: -7px;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.0001;
            content: " ";
          }

          .ant-menu-submenu > .ant-menu {
            background-color: #fff;
            border-radius: 4px;
          }

          .ant-menu-submenu > .ant-menu-submenu-title::after {
            -webkit-transition: -webkit-transform 0.3s
              cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: -webkit-transform 0.3s
              cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          .ant-menu-submenu-vertical
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow,
          .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow,
          .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow,
          .ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow {
            position: absolute;
            top: 50%;
            right: 16px;
            width: 10px;
            -webkit-transition: -webkit-transform 0.3s
              cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: -webkit-transform 0.3s
              cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          .ant-menu-submenu-vertical
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-vertical
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after {
            position: absolute;
            width: 6px;
            height: 1.5px;
            background: #fff;
            background: rgba(0, 0, 0, 0.65) \9;
            background-image: -webkit-gradient(
              linear,
              left top,
              right top,
              from(rgba(0, 0, 0, 0.65)),
              to(rgba(0, 0, 0, 0.65))
            );
            background-image: linear-gradient(
              to right,
              rgba(0, 0, 0, 0.65),
              rgba(0, 0, 0, 0.65)
            );
            background-image: none \9;
            border-radius: 2px;
            -webkit-transition: background 0.3s
                cubic-bezier(0.645, 0.045, 0.355, 1),
              top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            content: "";
          }

          .ant-menu-submenu-vertical
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before {
            -webkit-transform: rotate(45deg) translateY(-2px);
            -ms-transform: rotate(45deg) translateY(-2px);
            transform: rotate(45deg) translateY(-2px);
          }

          .ant-menu-submenu-vertical
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after {
            -webkit-transform: rotate(-45deg) translateY(2px);
            -ms-transform: rotate(-45deg) translateY(2px);
            transform: rotate(-45deg) translateY(2px);
          }

          .ant-menu-submenu-vertical
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-inline
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-vertical
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-inline
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow::before {
            background: -webkit-gradient(
              linear,
              left top,
              right top,
              from(#1890ff),
              to(#1890ff)
            );
            background: linear-gradient(to right, #1890ff, #1890ff);
          }

          .ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before {
            -webkit-transform: rotate(-45deg) translateX(2px);
            -ms-transform: rotate(-45deg) translateX(2px);
            transform: rotate(-45deg) translateX(2px);
          }

          .ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after {
            -webkit-transform: rotate(45deg) translateX(-2px);
            -ms-transform: rotate(45deg) translateX(-2px);
            transform: rotate(45deg) translateX(-2px);
          }

          .ant-menu-submenu-open.ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow {
            -webkit-transform: translateY(-2px);
            -ms-transform: translateY(-2px);
            transform: translateY(-2px);
          }

          .ant-menu-submenu-open.ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after {
            -webkit-transform: rotate(-45deg) translateX(-2px);
            -ms-transform: rotate(-45deg) translateX(-2px);
            transform: rotate(-45deg) translateX(-2px);
          }

          .ant-menu-submenu-open.ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before {
            -webkit-transform: rotate(45deg) translateX(2px);
            -ms-transform: rotate(45deg) translateX(2px);
            transform: rotate(45deg) translateX(2px);
          }

          .ant-menu-vertical .ant-menu-submenu-selected,
          .ant-menu-vertical-left .ant-menu-submenu-selected,
          .ant-menu-vertical-right .ant-menu-submenu-selected {
            color: #1890ff;
          }

          .ant-menu-vertical .ant-menu-submenu-selected > a,
          .ant-menu-vertical-left .ant-menu-submenu-selected > a,
          .ant-menu-vertical-right .ant-menu-submenu-selected > a {
            color: #1890ff;
          }

          .ant-menu-horizontal {
            line-height: 46px;
            white-space: nowrap;
            border: 0;
            border-bottom: 1px solid #e8e8e8;
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          .ant-menu-horizontal > .ant-menu-item,
          .ant-menu-horizontal > .ant-menu-submenu {
            position: relative;
            top: 1px;
            display: inline-block;
            vertical-align: bottom;
            border-bottom: 2px solid transparent;
          }

          .ant-menu-horizontal > .ant-menu-item:hover,
          .ant-menu-horizontal > .ant-menu-submenu:hover,
          .ant-menu-horizontal > .ant-menu-item-active,
          .ant-menu-horizontal > .ant-menu-submenu-active,
          .ant-menu-horizontal > .ant-menu-item-open,
          .ant-menu-horizontal > .ant-menu-submenu-open,
          .ant-menu-horizontal > .ant-menu-item-selected,
          .ant-menu-horizontal > .ant-menu-submenu-selected {
            color: #1890ff;
            border-bottom: 2px solid #1890ff;
          }

          .ant-menu-horizontal > .ant-menu-item > a {
            display: block;
            color: rgba(0, 0, 0, 0.65);
          }

          .ant-menu-horizontal > .ant-menu-item > a:hover {
            color: #1890ff;
          }

          .ant-menu-horizontal > .ant-menu-item > a::before {
            bottom: -2px;
          }

          .ant-menu-horizontal > .ant-menu-item-selected > a {
            color: #1890ff;
          }

          .ant-menu-horizontal::after {
            display: block;
            clear: both;
            height: 0;
            content: "\20";
          }

          .ant-menu-vertical .ant-menu-item,
          .ant-menu-vertical-left .ant-menu-item,
          .ant-menu-vertical-right .ant-menu-item,
          .ant-menu-inline .ant-menu-item {
            position: relative;
          }

          .ant-menu-vertical .ant-menu-item::after,
          .ant-menu-vertical-left .ant-menu-item::after,
          .ant-menu-vertical-right .ant-menu-item::after,
          .ant-menu-inline .ant-menu-item::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            border-right: 3px solid #1890ff;
            -webkit-transform: scaleY(0.0001);
            -ms-transform: scaleY(0.0001);
            transform: scaleY(0.0001);
            opacity: 0;
            -webkit-transition: opacity 0.15s
                cubic-bezier(0.215, 0.61, 0.355, 1),
              -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
              -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
              opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
              opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
              -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            content: "";
          }

          .ant-menu-vertical .ant-menu-item,
          .ant-menu-vertical-left .ant-menu-item,
          .ant-menu-vertical-right .ant-menu-item,
          .ant-menu-inline .ant-menu-item,
          .ant-menu-vertical .ant-menu-submenu-title,
          .ant-menu-vertical-left .ant-menu-submenu-title,
          .ant-menu-vertical-right .ant-menu-submenu-title,
          .ant-menu-inline .ant-menu-submenu-title {
            height: 40px;
            // margin-top: 4px;
            // margin-bottom: 4px;
            padding: 0 16px;
            overflow: hidden;
            font-size: 14px;
            line-height: 40px;
            text-overflow: ellipsis;
          }

          .ant-menu-vertical .ant-menu-submenu,
          .ant-menu-vertical-left .ant-menu-submenu,
          .ant-menu-vertical-right .ant-menu-submenu,
          .ant-menu-inline .ant-menu-submenu {
            padding-bottom: 0.02px;
          }

          .ant-menu-vertical > .ant-menu-item,
          .ant-menu-vertical-left > .ant-menu-item,
          .ant-menu-vertical-right > .ant-menu-item,
          .ant-menu-inline > .ant-menu-item,
          .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
          .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
          .ant-menu-vertical-right
            > .ant-menu-submenu
            > .ant-menu-submenu-title,
          .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
            height: 40px;
            line-height: 40px;
          }

          .ant-menu-inline {
            width: 100%;
          }

          .ant-menu-inline .ant-menu-selected::after,
          .ant-menu-inline .ant-menu-item-selected::after {
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
            opacity: 1;
            -webkit-transition: opacity 0.15s
                cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
              opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
              opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          .ant-menu-inline .ant-menu-item,
          .ant-menu-inline .ant-menu-submenu-title {
            width: calc(100% + 1px);
          }

          .ant-menu-inline .ant-menu-submenu-title {
            padding-right: 34px;
          }

          .ant-menu-inline-collapsed {
            width: 80px;
          }

          .ant-menu-inline-collapsed > .ant-menu-item,
          .ant-menu-inline-collapsed
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-item,
          .ant-menu-inline-collapsed
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-submenu
            > .ant-menu-submenu-title,
          .ant-menu-inline-collapsed
            > .ant-menu-submenu
            > .ant-menu-submenu-title {
            left: 0;
            padding: 0 32px !important;
            text-overflow: clip;
          }

          .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
          .ant-menu-inline-collapsed
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-item
            .ant-menu-submenu-arrow,
          .ant-menu-inline-collapsed
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-submenu
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow,
          .ant-menu-inline-collapsed
            > .ant-menu-submenu
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow {
            display: none;
          }

          .ant-menu-inline-collapsed > .ant-menu-item .anticon,
          .ant-menu-inline-collapsed
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-item
            .anticon,
          .ant-menu-inline-collapsed
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-submenu
            > .ant-menu-submenu-title
            .anticon,
          .ant-menu-inline-collapsed
            > .ant-menu-submenu
            > .ant-menu-submenu-title
            .anticon {
            margin: 0;
            font-size: 16px;
            line-height: 40px;
          }

          .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
          .ant-menu-inline-collapsed
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-item
            .anticon
            + span,
          .ant-menu-inline-collapsed
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-submenu
            > .ant-menu-submenu-title
            .anticon
            + span,
          .ant-menu-inline-collapsed
            > .ant-menu-submenu
            > .ant-menu-submenu-title
            .anticon
            + span {
            display: inline-block;
            max-width: 0;
            opacity: 0;
          }

          .ant-menu-inline-collapsed-tooltip {
            pointer-events: none;
          }

          .ant-menu-inline-collapsed-tooltip .anticon {
            display: none;
          }

          .ant-menu-inline-collapsed-tooltip a {
            color: rgba(255, 255, 255, 0.85);
          }

          .ant-menu-inline-collapsed .ant-menu-item-group-title {
            padding-right: 4px;
            padding-left: 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .ant-menu-item-group-list {
            margin: 0;
            padding: 0;
          }

          .ant-menu-item-group-list .ant-menu-item,
          .ant-menu-item-group-list .ant-menu-submenu-title {
            padding: 0 16px 0 28px;
          }

          .ant-menu-root.ant-menu-vertical,
          .ant-menu-root.ant-menu-vertical-left,
          .ant-menu-root.ant-menu-vertical-right,
          .ant-menu-root.ant-menu-inline {
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          .ant-menu-sub.ant-menu-inline {
            padding: 0;
            border: 0;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          .ant-menu-sub.ant-menu-inline > .ant-menu-item,
          .ant-menu-sub.ant-menu-inline
            > .ant-menu-submenu
            > .ant-menu-submenu-title {
            height: 40px;
            line-height: 40px;
            list-style-position: inside;
            list-style-type: disc;
          }

          .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
            padding-left: 32px;
          }

          .ant-menu-item-disabled,
          .ant-menu-submenu-disabled {
            color: rgba(0, 0, 0, 0.25) !important;
            background: none;
            border-color: transparent !important;
            cursor: not-allowed;
          }

          .ant-menu-item-disabled > a,
          .ant-menu-submenu-disabled > a {
            color: rgba(0, 0, 0, 0.25) !important;
            pointer-events: none;
          }

          .ant-menu-item-disabled > .ant-menu-submenu-title,
          .ant-menu-submenu-disabled > .ant-menu-submenu-title {
            color: rgba(0, 0, 0, 0.25) !important;
            cursor: not-allowed;
          }

          .ant-menu-item-disabled
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-disabled
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-item-disabled
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after,
          .ant-menu-submenu-disabled
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after {
            background: rgba(0, 0, 0, 0.25) !important;
          }

          .ant-menu-dark,
          .ant-menu-dark .ant-menu-sub {
            color: rgba(255, 255, 255, 0.65);
            background: #001529;
          }

          .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-sub
            .ant-menu-submenu-title
            .ant-menu-submenu-arrow {
            opacity: 0.45;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
          }

          .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-sub
            .ant-menu-submenu-title
            .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-sub
            .ant-menu-submenu-title
            .ant-menu-submenu-arrow::before {
            background: #fff;
          }

          .ant-menu-dark.ant-menu-submenu-popup {
            background: transparent;
          }

          .ant-menu-dark .ant-menu-inline.ant-menu-sub {
            background: #000c17;
            -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
          }

          .ant-menu-dark.ant-menu-horizontal {
            border-bottom: 0;
          }

          .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
          .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
            top: 0;
            margin-top: 0;
            border-color: #001529;
            border-bottom: 0;
          }

          .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
            bottom: 0;
          }

          .ant-menu-dark .ant-menu-item,
          .ant-menu-dark .ant-menu-item-group-title,
          .ant-menu-dark .ant-menu-item > a {
            color: rgba(255, 255, 255, 0.65);
          }

          .ant-menu-dark.ant-menu-inline,
          .ant-menu-dark.ant-menu-vertical,
          .ant-menu-dark.ant-menu-vertical-left,
          .ant-menu-dark.ant-menu-vertical-right {
            border-right: 0;
          }

          .ant-menu-dark.ant-menu-inline .ant-menu-item,
          .ant-menu-dark.ant-menu-vertical .ant-menu-item,
          .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
          .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
            left: 0;
            margin-left: 0;
            border-right: 0;
          }

          .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
          .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
          .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
          .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
            border-right: 0;
          }

          .ant-menu-dark.ant-menu-inline .ant-menu-item,
          .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
            width: 100%;
          }

          .ant-menu-dark .ant-menu-item:hover,
          .ant-menu-dark .ant-menu-item-active,
          .ant-menu-dark .ant-menu-submenu-active,
          .ant-menu-dark .ant-menu-submenu-open,
          .ant-menu-dark .ant-menu-submenu-selected,
          .ant-menu-dark .ant-menu-submenu-title:hover {
            color: #fff;
            background-color: transparent;
          }

          .ant-menu-dark .ant-menu-item:hover > a,
          .ant-menu-dark .ant-menu-item-active > a,
          .ant-menu-dark .ant-menu-submenu-active > a,
          .ant-menu-dark .ant-menu-submenu-open > a,
          .ant-menu-dark .ant-menu-submenu-selected > a,
          .ant-menu-dark .ant-menu-submenu-title:hover > a {
            color: #fff;
          }

          .ant-menu-dark
            .ant-menu-item:hover
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-item-active
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-submenu-active
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-submenu-open
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-submenu-selected
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-submenu-title:hover
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-item:hover
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-item-active
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-submenu-active
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-submenu-open
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-submenu-selected
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow,
          .ant-menu-dark
            .ant-menu-submenu-title:hover
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow {
            opacity: 1;
          }

          .ant-menu-dark
            .ant-menu-item:hover
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-item-active
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-active
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-open
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-selected
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-title:hover
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-item:hover
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-item-active
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-active
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-open
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-selected
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-title:hover
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-item:hover
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-item-active
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-active
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-open
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-selected
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-title:hover
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-item:hover
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-item-active
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-active
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-open
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-selected
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-title:hover
            > .ant-menu-submenu-title:hover
            > .ant-menu-submenu-arrow::before {
            background: #fff;
          }

          .ant-menu-dark .ant-menu-item:hover {
            background-color: transparent;
          }

          .ant-menu-dark .ant-menu-item-selected {
            color: #fff;
            border-right: 0;
          }

          .ant-menu-dark .ant-menu-item-selected::after {
            border-right: 0;
          }

          .ant-menu-dark .ant-menu-item-selected > a,
          .ant-menu-dark .ant-menu-item-selected > a:hover {
            color: #fff;
          }

          .ant-menu-dark .ant-menu-item-selected .anticon {
            color: #fff;
          }

          .ant-menu-dark .ant-menu-item-selected .anticon + span {
            color: #fff;
          }

          .ant-menu.ant-menu-dark .ant-menu-item-selected,
          .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
            background-color: #1890ff;
          }

          .ant-menu-dark .ant-menu-item-disabled,
          .ant-menu-dark .ant-menu-submenu-disabled,
          .ant-menu-dark .ant-menu-item-disabled > a,
          .ant-menu-dark .ant-menu-submenu-disabled > a {
            color: rgba(255, 255, 255, 0.35) !important;
            opacity: 0.8;
          }

          .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
          .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
            color: rgba(255, 255, 255, 0.35) !important;
          }

          .ant-menu-dark
            .ant-menu-item-disabled
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-submenu-disabled
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::before,
          .ant-menu-dark
            .ant-menu-item-disabled
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after,
          .ant-menu-dark
            .ant-menu-submenu-disabled
            > .ant-menu-submenu-title
            > .ant-menu-submenu-arrow::after {
            background: rgba(255, 255, 255, 0.35) !important;
          }
        }
        .login_user:hover .login_user_infor {
          display: block !important;
        }
        #INFO_list {
          li {
            cursor: pointer;
          }
        }
        .login_user_infor {
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
          padding: 15px 15px 0 !important;

          .ant-menu-item {
            height: 60px !important;
            line-height: 60px !important;
            border-top: 1px solid #eee;
            padding: 5px;
          }
        }
        .block-right-after {
          display: flex;
          align-items: center;
          .right_item {
            margin-right: 24px;
            display: flex;
            align-items: center;
          }
          .img1 {
            position: relative;
          }
          .img1_center {
            position: absolute;
            top: 47px;
            border-radius: 10px;
            right: 0;
            display: none;
            width: 220px;
            z-index: 999;
            background-color: #fff;
            box-shadow: 0 0 2px #e1dcdc;
            li {
              padding: 0 16px;
              overflow: hidden;
              font-size: 14px;
              line-height: 40px;
              text-overflow: ellipsis;

              div {
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .img1:hover .img1_center {
            display: block;
          }
          .right-after-project {
            justify-content: center;
            width: 96px;
            height: 30px;
            border: 1px solid #d0d1d4;
            border-color: #1d6af2;
            border-radius: 2px;
          }
          .right-after-avatar {
            width: 34px;
            height: 34px;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0 0 2px #e1dcdc;
          }
          .right-after-sign {
            // line-height: 1;
          }
        }
      }
    }
  }

  // 模态框样式
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    // width: 100vw;
    // height: 100vh;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    // 登录的样式
    /* 自己添加的样式 */

    ::v-deep #login_wx .impowerBox .info p {
      display: none !important;
    }
    body {
      background-color: #666;
    }
    /* 左右布局 */
    .txt {
      width: 149px;
      background-image: url(@/assets/img/login-tip.svg);
      position: absolute;
      top: 20px;
      right: 56px;
      width: 164px;
      height: 36px;
      padding: 8px 0 0 8px;
      font-size: 14px;
      font-weight: 400;
      color: #005cfa;
      line-height: 20px;
      letter-spacing: 0.25px;
      -webkit-user-select: none;
      user-select: none;
    }
    .container {
      display: flex;
      width: 847px;
      height: 546px !important;
      align-items: center;
      background: #fff;
      padding: 0;
      .left {
        text-align: center;
        .top {
          font-size: 18px;
          font-weight: 700;
          color: #ccc;
        }
        .center {
          font-size: 23px;
          font-weight: 700;
          color: #ccc;
        }
      }
    }
    .container .left .img img {
      width: 375px;
    }
    .justify-content-center {
      width: 100%;
      text-align: center;
    }
    .passport-ctr {
      width: 320px;
    }
    /* 设置placeholder样式 */
    .form-group > input::placeholder {
      color: #bfbfbf;
      font-size: 14px;
    }
    .form-group {
      text-align: left;
    }
    #form_login {
      /* border: 1px solid #cccccc; */
      border-radius: 2px;
      background: #ffffff;
      padding: 20px 28px;
      margin-top: 30px;
      margin-left: -20px;
    }
    .passport-link {
      text-align: center;
      /* border: 1px solid #cccccc; */
      border-radius: 2px;
      background: #ffffff;
      margin-top: 17px;
      line-height: 45px;
      height: 45px;
      width: 320px;
      margin-left: -20px;
    }
    .socialite-desc {
      text-align: center;
      line-height: 2;
      margin-top: 17px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .line-border {
      display: inline-block;
      height: 1px;
      width: 60px;
      background-color: #ccc;
      line-height: 60px;
    }
    .line-center {
      margin: 0 30px;
    }

    /* 弹出框样式 */
    .popup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .popup-content {
      width: 398px;
      height: 546px;
      background-color: #fff;
      padding-top: 80px;
      position: relative;
    }

    .close-btn {
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
      background-color: #f1f1f1;
      border: none;
      /* padding: 8px 12px; */
    }
    .close-btn img {
    }
    /* .close-btn .back {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 170px;
  top: 447px;
  width: 141px;
  height: 52px;
  background: #fff;
} */
    /* Fade transition */
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s ease;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
    /* 设置整体的最小宽度 */
    .col-4 {
      min-width: 300px;
    }
    .yanzheng {
      position: absolute;
      right: 0;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #999;
      line-height: 24px;
      transform: translate(-13px, -38px);
      /* pointer-events: none; */
    }
    /* 输入框的样式 */
    .form-control:hover {
      border: 1px solid #007bff;
    }
    .impowerBox {
      margin-top: 30px !important;
    }
    .container {
      min-height: 546px;
    }
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    ::v-deep input[type="number"] {
      -moz-appearance: textfield;
    }
    .content-height {
      height: 70%;
    }
    .modal-content {
      // background-color: #fefefe;
      padding: 20px;
      border: 1px solid #888;
      cursor: move;
      user-select: none;
      min-width: 360px;
      min-height: 260px;
      // position: absolute;
      .scrolling-text {
        white-space: nowrap;
        display: inline-block;
        animation: scrollText linear infinite;
      }

      @keyframes scrollText {
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(-100%);
        }
      }
    }
    .close {
      color: #aaa;
      float: right;
      font-size: 30px;
      font-weight: bold;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 16px;
    }

    .close:hover,
    .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }

  // 这是轮播图的样式
  .turn_on {
    position: relative;
    // top: -60px;

    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #fff;
    }
    // end
    .h-modules-box {
      width: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      // top: 552px;
      z-index: 9;
      background: hsla(0, 0%, 100%, 0.4);
      .h-modules {
        display: flex;
        justify-content: center;
        min-width: 1200px;
        height: 90px;
        margin: 0 auto;
        a {
          display: inline-block;
          height: 90px;
          // width: 400px;
          width: 395px;
          font-size: 16px;
          .title {
            font-weight: 700;
            color: rgba(0, 0, 0, 0.8);
            line-height: 22px;
          }
          .txt {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 6px;
          }
        }
        a > div {
          height: 90px;
          display: flex;
          align-items: center;
          height: 100%;
        }
        img {
          margin-left: 6.1%;
          margin-right: 4%;
        }
      }
    }
    .box {
      /* 重点：盒子宽度 600 */
      position: relative;
      min-width: 1300px;
      max-width: 1600px;
      height: 560px;
      // border: 3px solid black;
      margin: 24px auto 30px;
      #one {
        min-width: 1300px;
        max-width: 1600px;
        overflow: hidden;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          // height: 100%;
          animation: move1 45s linear infinite;
          .item {
            height: 100%;
            /* 重点：单个图片宽度 200，相当于默认不动可见 3 个 */
            width: 200px;
            flex-shrink: 0;
            min-width: 330px;
            height: 240px;
            margin-top: 24px;
            margin-right: 24px;
            border-radius: 8px;
            background-color: #fff;
            cursor: pointer;
            img {
              height: 100%;
              width: 100%;
            }
          }
          @keyframes move1 {
            /* 为什么设置 -800px ？公式：主图张数 * 单个图片宽度（每轮主图只有 4 张，4 张照片为一个周期，如果需要轮播的主图为 7、8、9...张，都一样） */

            0% {
              transform: translateX(0%);
            }
            100% {
              // transform: translateX(-177%);
              transform: translateX(-9556px);
            }
          }
        }
        ul:hover {
          animation-play-state: paused;
        }
      }
      #two {
        min-width: 1300px;
        max-width: 1600px;
        overflow: hidden;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          // height: 100%;
          animation: move2 39s linear infinite;
          .item {
            height: 100%;
            /* 重点：单个图片宽度 200，相当于默认不动可见 3 个 */
            background-color: #fff;
            width: 200px;
            flex-shrink: 0;
            min-width: 330px;
            height: 240px;
            margin-top: 24px;
            margin-right: 24px;
            border-radius: 8px;
            cursor: pointer;
            img {
              height: 100%;
              width: 100%;
            }
          }
          @keyframes move2 {
            0% {
              // transform: translateX(-278%);
              // transform: translateX(-9285px);
              transform: translateX(-9260px);
              // transform: translateX(-7696px);
            }
            100% {
              // transform: translateX(-75.5%);
              // transform: translateX(-2301px);
              transform: translateX(30px);
              // transform: translateX(106px);
            }
          }
        }
        ul:hover {
          animation-play-state: paused;
        }
        // 反方向
      }
      /* hover 暂停，不用可去掉 */
      ul:hover {
        animation-play-state: paused;
      }
    }

    .part2-plots-shadow-right {
      width: 40px;
      height: 503px;
      background: linear-gradient(
        -90deg,
        #fff,
        #fff 6%,
        hsla(0, 0%, 100%, 0.48)
      );
      position: absolute;
      right: 0;
      top: 26px;
    }

    .part2-plots-shadow-left {
      width: 40px;
      height: 503px;
      background: linear-gradient(
        90deg,
        #fff,
        #fff 6%,
        hsla(0, 0%, 100%, 0.48)
      );
      position: absolute;
      left: 0;
      top: 26px;
      z-index: 2;
    }
  }
  .bottom_contain {
    min-width: 1200px;
    max-width: 1800px;
    // width: 1400px;
    margin: 0 auto;
    margin-top: 100px;
    .h-section-part2-title {
      margin-top: 60px;
      margin-bottom: 16px;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.8);
      line-height: 42px;
    }
    .h-section-part2-subtitle {
      text-align: center;
      white-space: nowrap;
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
      line-height: 36px;
      margin-bottom: 24px;
    }
    .h-section-part2-analyse {
      width: 50%;
      // height: 539px;
      text-align: center;
      background: #e5e9ea;
      border-radius: 12px;
      margin: 0 auto 30px;
      .part2-analyse-top-part {
        // display: flex;
        // justify-content: space-between;
        .h-section-part2-analyse-list {
          // height: 439px;
          ul {
            li {
              // width: 170px;
              min-height: 50px;
              font-size: 15px;
              color: #ff7713;
              font-weight: 700;
              padding: 5px;
              display: flex;
              align-items: center;
              background: #fff;
              border-radius: 8px;
              margin-left: 24px;
              margin-top: 24px;
              // text-align: start;
              cursor: pointer;
              .part2-icon-style {
                display: inline-block;
                padding: 0 10px 0 20px;
                color: #999;
              }
            }
          }
          ul:last-child {
            margin-right: 24px;
          }
        }
        .section-part2-analyse-details {
          display: flex;
          text-align: start;
          width: 785px;
          height: 415px;
          background: #fff;
          border-radius: 8px;
          margin: 24px;
          position: relative;
          .section-part2-analyse-advantage {
            font-size: 18px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.85);
            line-height: 18px;
            margin: 20px;
            border-left: 6px solid #1d6af2;
            padding-left: 6px;
          }
          .analyse-details-left ul li {
            width: 380px;
            height: 20px;
            font-size: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.5);
            line-height: 24px;
            list-style: outside;
            margin: 24px 24px 24px 40px;
          }
          .analyse-details-left {
            .analyse-advantage-button {
              text-align: center;
              line-height: 48px;
              width: 140px;
              height: 48px;
              background: linear-gradient(270deg, #1d6af2, #3ea3fa);
              border-radius: 8px;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              margin-left: 20px;
              margin-top: 35px;
            }
          }
          //
          img {
            width: 500px;
            height: 300px;
            position: absolute;
            right: 0;
            top: 47px;
            right: 22px;
          }
        }
      }
      .part2-analyse-list-more {
        display: inline-block;
        // width: 1152px;
        width: 95%;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-weight: 700;
        color: #1d6af2;
        background: #fff;
        border-radius: 8px;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 20px;
        text-align: center;
        cursor: pointer;
        .more-icon-style {
          display: inline-block;
          margin-left: 6px;
          width: 20px;
          height: 20px;
          border-radius: 12px;
          background: linear-gradient(90deg, #3ea3f9, #1d6af2);
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }
    .text {
      .video-container {
        margin-top: 26px;
        height: 415px;
        // background: #fff;
        margin-left: 18px;
        border-radius: 17px;
        margin-right: 18px;
        position: relative;
        // padding-bottom: 75%; /* 根据视频比例调整容器高度 */
      }

      video {
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        height: 100%;
      }
    }
    // 第三个class
    .h-section-part2-title {
      margin-top: 60px;
      margin-bottom: 16px;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.8);
      line-height: 42px;
    }
    .h-section-part2-subtitle {
      text-align: center;
      white-space: nowrap;
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
      line-height: 36px;
      margin-bottom: 24px;
    }
    .h-section-part2-plots {
      width: 1200px;
      height: 560px;
      background: #f3f4f4;
      border-radius: 12px;
      margin: 0 auto 30px;
      position: relative;
      .h-section-part2-plots-top {
        width: 1200px;
        overflow: hidden;
        ul {
          display: flex;
          margin: 0;
          padding: 0;
          list-style: none;
          -webkit-animation: hotList 13s linear infinite;
          animation: hotList 13s linear infinite;
          li {
            width: 330px;
            min-width: 330px;
            height: 240px;
            margin-top: 24px;
            margin-right: 24px;
            border-radius: 8px;
            cursor: pointer;
            img {
              border: none;
              vertical-align: middle;
            }
          }
        }
      }
      .h-section-part2-plots-bottom {
        width: 1200px;
        overflow: hidden;
        ul {
          display: flex;
          flex-direction: row-reverse;
          margin: 0;
          padding: 0;
          list-style: none;
          -webkit-animation: latestList 13s linear infinite;
          animation: latestList 13s linear infinite;
          li {
            width: 330px;
            min-width: 330px;
            height: 240px;
            margin-top: 24px;
            margin-right: 24px;
            border-radius: 8px;
            cursor: pointer;
            img {
              border: none;
              vertical-align: middle;
            }
          }
        }
      }
      .part2-plots-shadow-left {
        width: 40px;
        height: 560px;
        background: linear-gradient(
          90deg,
          #fff,
          #fff 6%,
          hsla(0, 0%, 100%, 0.48)
        );
        position: absolute;
        left: 0;
        top: 0;
      }
      .part2-plots-shadow-right {
        width: 40px;
        height: 560px;
        background: linear-gradient(
          -90deg,
          #fff,
          #fff 6%,
          hsla(0, 0%, 100%, 0.48)
        );
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .section-part2-viewmore-button {
      display: flex;
      justify-self: center;
      align-items: center;
      width: 162px;
      height: 48px;
      margin: 0 auto;
      background: linear-gradient(270deg, #1d6af2, #3ea3fa);
      border-radius: 8px;
      border: none;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      line-height: 48px;
      cursor: pointer;
    }
    .section-part2-viewmore-button div:first-child {
      width: 112px;
      height: 48px;
      margin-left: 12px;
    }
    .section-part2-viewmore-button div:nth-child(2) {
      width: 20px;
      height: 20px;
      border-radius: 13px;
      background: linear-gradient(90deg, hsla(0, 0%, 100%, 0.53), #fff);
      color: #1e6cf3;
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
      .anticon {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        svg {
          display: inline-block;
          vertical-align: inherit;
        }
      }
    }
    // 第四个class
    .h-section-part2-title {
      margin-top: 60px;
      margin-bottom: 16px;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.8);
      line-height: 42px;
    }
    .h-section-part2-subtitle {
      text-align: center;
      white-space: nowrap;
      font-size: 21px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
      line-height: 36px;
      margin-bottom: 24px;
    }
    .h-section-part2-college {
      width: 1200px;
      height: 670px;
      background: #f3f4f4;
      margin: 0 auto 30px;
    }
    .h-section-part2 .section-part2-viewmore-button {
      display: flex;
      justify-self: center;
      align-items: center;
      width: 162px;
      height: 48px;
      margin: 0 auto;
      background: linear-gradient(270deg, #1d6af2, #3ea3fa);
      border-radius: 8px;
      border: none;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      line-height: 48px;
      cursor: pointer;
      div:first-child {
        width: 112px;
        height: 48px;
        margin-left: 12px;
      }
      div:nth-child(2) {
        width: 20px;
        height: 20px;
        border-radius: 13px;
        background: linear-gradient(90deg, hsla(0, 0%, 100%, 0.53), #fff);
        color: #1e6cf3;
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        .anticon {
          display: inline-block;
          color: inherit;
          font-style: normal;
          line-height: 0;
          text-align: center;
          text-transform: none;
          vertical-align: -0.125em;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
    // 底部总结
    .h-section-part3 {
      width: 100%;
      text-align: center;
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.2), #e1f5ff);
      border: 1px solid #c9e4ff;
      margin-bottom: 120px;
      .h-section-part3-usedata {
        width: 1200px;
        margin: 0 auto;
        ul {
          display: flex;
          li:first-child {
            border-left: 1px solid #c9e4ff;
          }
          li {
            display: flex;
            justify-self: center;
            align-items: flex-start;
            width: 298px;
            height: 132px;
            border-right: 1px solid #c9e4ff;
            text-align: start;
            cursor: pointer;
            img {
              width: 50px;
              height: 50px;
              margin: 20px 11px;
            }
            .h-section-usedata-box {
              .h-section-usedata-txt {
                margin-top: 22px;
                margin-bottom: 8px;
                height: 25px;
                font-size: 18px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.6);
                line-height: 25px;
              }
              .h-section-usedata-data {
                color: rgba(0, 0, 0, 0.8);
                span:first-child {
                  font-size: 48px;
                  line-height: 57px;
                }
                span:nth-child(2) {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
      .section-part3-user-login {
        height: 260px;
        background: url(https://pic.616pic.com/bg_w1180/00/00/49/6DvLdLKioU.jpg)
          no-repeat 50%;
        background-size: cover;
        // overflow: hidden;
        position: relative;
        .section-part3-login-bg {
          width: 100%;
          min-width: 1350px;
          height: 260px;
          background: linear-gradient(
            180deg,
            rgba(64, 66, 72, 0.75),
            rgba(36, 41, 51, 0.86)
          );
          // position: absolute;
          // top: 0;
          .section-part3-login-txt {
            font-size: 18px;
            color: #fff;
            line-height: 25px;
            // padding-top: 46px;
            white-space: nowrap;
            z-index: 99;
          }
          .hide {
            // display: none !important;

            .ant-btn > i,
            .ant-btn > span {
              display: inline-block;
              -webkit-transition: margin-left 0.3s
                cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              pointer-events: none;
            }
          }
          .section-part3-login-button {
            width: 180px;
            height: 42px;
            margin-top: 11px;
            color: #1d6af2;
            border: none;
            border-radius: 8px;
            font-size: 18px;
            background: #fff;
            line-height: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .ma {
    position: fixed;
    right: 0;
    width: 100px;
    // height: 72px;
    height: auto;
    padding: 15px 0;
    top: 50%;
    z-index: 999;
    // background: #fff;
    .right-box {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
    .qrcode {
      position: absolute;
      right: 80px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 125px;
      width: 100px;
      background: #fff;

      .txt {
        margin-top: 10px;
      }
    }
  }
}
@keyframes shake {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
.shake-text {
  animation: shake 2s infinite;
}
.turn_on {
  ::v-deep .el-carousel__arrow {
    display: none !important;
  }
}
.iconfont1 {
  margin-right: 5px;
}
::v-deep .el-collapse-item__header{
  height: 66px;
  line-height: 30px;
  }
</style>